import { notification } from "antd";
import i18n from "stores/i18n";

//USAGE
// 1) Basic usage, just call the function, eg. createSuccess()
// 2) for default messages, pass in an object
// either {message, description}
// or {title, text}
// Same result

function createSuccess(param) {
  switch (typeof param) {
    case "string":
      notification["success"]({
        message: i18n.getText(param),
      });
      break;
    case "object":
      notification["success"]({
        message: param.title || param.message,
        description: param.text || param.description,
      });
      break;
    default:
      notification["success"]({
        message: i18n.getText("createSuccess"),
      });
  }
}

function updateSuccess(param) {
  switch (typeof param) {
    case "string":
      notification["success"]({
        message: i18n.getText(param || "updateSuccess"),
      });
      break;
    case "object":
      notification["success"]({
        message: param.title || param.message,
        description: param.text || param.description,
      });
      break;
    default:
      notification["success"]({
        message: i18n.getText("updateSuccess"),
      });
  }
}

function deleteSuccess(param) {
  switch (typeof param) {
    case "string":
      notification["success"]({
        message: i18n.getText(param || "deleteSuccess"),
      });
      break;
    case "object":
      notification["success"]({
        message: param.title || param.message,
        description: param.text || param.description,
      });
      break;
    default:
      notification["success"]({
        message: i18n.getText("deleteSuccess"),
      });
  }
}

export { createSuccess, updateSuccess, deleteSuccess };
