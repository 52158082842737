const boPrefix = "/bo";
const api = {
  //Login
  checkUser2FA: {
    url: boPrefix + "/login/login-1",
    method: "post",
    headers: {},
  },
  userLogin: {
    url: boPrefix + "/login/login-2",
    method: "post",
    headers: {},
  },
  userLogout: {
    url: boPrefix + "/login/logout",
    method: "post",
    headers: {},
  },
  checkSession: {
    url: boPrefix + "/login/check-session",
    method: "post",
    headers: {},
  },

  //Order Management
  orderStatus: {
    url: boPrefix + "/order_status",
    method: "get",
    headers: {},
  },
  orderList: {
    url: boPrefix + "/order_list",
    method: "post",
    headers: {},
  },
  orderExport: {
    url: boPrefix + "/export/order_list",
    method: "post",
    headers: {},
  },
  orderExportUserAction: {
    url: boPrefix + "/export/order_list_with_history",
    method: "post",
    headers: {},
    timeout: 600000,
  },
  orderUnpair: {
    url: boPrefix + "/order_unpair",
    method: "post",
    headers: {},
  },
  depNotifyTrs: {
    url: boPrefix + "/dep_tn",
    method: "post",
    headers: {},
  },
  depNotifyTrsWdOnHold: {
    url: boPrefix + "/dep_tn_wd_onhold",
    method: "post",
    headers: {},
  },
  withdrawGrpCancel: {
    url: boPrefix + "/wd_gc",
    method: "post",
    headers: {},
  },
  withdrawRelease: {
    url: boPrefix + "/wd_release",
    method: "post",
    headers: {},
  },
  depTransOpen: {
    url: boPrefix + "/dep_trans_open",
    method: "post",
    headers: {},
  },
  depReview: {
    url: boPrefix + "/dep_review",
    method: "post",
    headers: {},
  },
  depTransVerify: {
    url: boPrefix + "/dep_trans_verify",
    method: "post",
    headers: {},
  },
  depTransFake: {
    url: boPrefix + "/dep_trans_fake",
    method: "post",
    headers: {},
  },
  cancelMatch: {
    url: boPrefix + "/wd_fc",
    method: "post",
    headers: {},
  },
  rematch: {
    url: boPrefix + "/order_rematch",
    method: "post",
    headers: {},
  },
  withdrawOnHold: {
    url: boPrefix + "/wd_chk_to_onhold",
    method: "post",
    headers: {},
  },
  withdrawResumeCheck: {
    url: boPrefix + "/wd_chk_to_resume",
    method: "post",
    headers: {},
  },
  withdrawResume: {
    url: boPrefix + "/wd_resume",
    method: "post",
    headers: {},
  },
  informPssOfFraud: {
    url: boPrefix + "/inform_pss_of_fraud",
    method: "post",
    headers: {},
  },
  viewMatchHistoryDetails: { 
    url: boPrefix + "/view_match_history_details", 
    method: "get", 
    headers: {} 
  },
  updateTransAmt: {
    url: boPrefix + "/dep_update_trans_amt", 
    method: "post", 
    headers: {} 
  },
  channelTypeList: {
    url: boPrefix + "/channel_type_list",
    method: "get",
    headers: {},
  },
  brandNameList: {
    url: boPrefix + "/brand_name_list",
    method: "get",
    headers: {},
  },
  extendOrderTo: {
    url: boPrefix + "/extend_order_to",
    method: "post",
    headers: {}
  },
  extCheck: {
    url: boPrefix + "/ext_check",
    method: "post",
    headers: {}
  },
  listDepositorHistory:{
    url: boPrefix + "/list_depositor_history",
    method: "post",
    headers: {}
  },

  //User Management
  getUserRoles: {
    url: boPrefix + "/auth/role_listing",
    method: "get",
    headers: { "main-menu": "User Management" },
  },
  userList: {
    url: boPrefix + "/auth/user_list",
    method: "post",
    headers: {},
  },
  searchUser: {
    url: boPrefix + "/auth/search_user",
    method: "post",
    headers: {},
  },
  addUser: {
    url: boPrefix + "/auth/add_user",
    method: "post",
    headers: {},
  },
  updateMasterGlgSwitch: {
    url: boPrefix + "/auth/otp/update_glg_switch",
    method: "post",
    headers: {},
  },
  getGoogleSwitch: {
    url: boPrefix + "/no_auth/get_google_switch",
    method: "get",
    headers: {},
  },
  resetUserPass: {
    url: boPrefix + "/auth/adm_reset_pw",
    method: "post",
    headers: {},
  },
  editUser: {
    url: boPrefix + "/auth/update_user",
    method: "post",
    headers: {},
  },
  deleteUser: {
    url: boPrefix + "/auth/delete_user",
    method: "post",
    headers: {},
  },

  //New Role Apis : the new ones has an additional /admin infront
  addRole: {
    url: boPrefix + "/auth/add_role",
    method: "post",
    headers: {},
  },
  deleteRoleById: {
    url: boPrefix + "/auth/delete_role",
    method: "post",
    headers: {},
  },
  editRole: {
    url: boPrefix + "/auth/update_role",
    method: "post",
    headers: {},
  },
  searchRoleHierarchy: {
    url: boPrefix + "/auth/role_hierarchy",
    method: "get",
    headers: {},
  },
  showPermissionMenu: {
    url: boPrefix + "/auth/show_menu",
    method: "get",
    headers: {},
  },
  findRoleById: {
    url: boPrefix + "/auth/show_update",
    method: "get",
    headers: {},
  },
  refreshPerm: {
    url: boPrefix + "/auth/refresh",
    method: "post",
    headers: {},
  },
  countAssignee: {
    url: boPrefix + "/no_auth/count_assignee",
    method: "get",
    headers: {},
  },


  //Reports
  withdrawalKpi: {
    url: boPrefix + "/kpi_report",
    method: "post",
    headers: {},
  },
  depositKpi: {
    url: boPrefix + "/kpi_report",
    method: "post",
    headers: {},
  },

  //File Upload
  //fileUpload api does not require prefix "/bo" for the url
  fileUpload: {
    url: "/file-upload-inner/file/upload-file",
    method: "post",
    headers: {},
  },
  depTransVideo: {
    url: boPrefix + "/dep_trans_video",
    method: "post",
    headers: {},
  },

  //Merchant Management
  searchMerchant: {
    url: boPrefix + "/test",
    method: "post",
    headers: {},
  },
  addMerchant: {
    url: boPrefix + "/test",
    method: "post",
    headers: {},
  },
  editMerchant: {
    url: boPrefix + "/test",
    method: "post",
    headers: {},
  },

  //Credit Rating
  searchCreditScore: {
    url: boPrefix + "/search_credit_score",
    method: "post",
    headers: {},
  },
  viewIgnoreCreditUsers: {
    url: boPrefix + "/view_ignore_credit_users",
    method: "post",
    headers: {},
  },
  addIgnoreCreditUser: {
    url: boPrefix + "/add_ignore_credit_user",
    method: "post",
    headers: {},
  },
  removeIgnoreCreditUser: {
    url: boPrefix + "/remove_ignore_credit_user",
    method: "post",
    headers: {},
  },
  viewCreditConfig: {
    url: boPrefix + "/view_credit_config",
    method: "post",
    headers: {},
  },
  updateCreditConfig: {
    url: boPrefix + "/update_credit_config",
    method: "post",
    headers: {},
  },
  viewCreditLevel: {
    url: boPrefix + "/view_credit_level",
    method: "post",
    headers: {},
  },
  updateCreditLevel: {
    url: boPrefix + "/update_credit_level",
    method: "post",
    headers: {},
  },

  //Individual User
  viewProfile: {
    url: boPrefix + "/no_auth/view_profile",
    method: "post",
    headers: {},
  },
  unbindOTP: {
    url: boPrefix + "/no_auth/unbind",
    method: "post",
    headers: {},
  },
  resetPassword: {
    url: boPrefix + "/no_auth/reset_password",
    method: "post",
    headers: {},
  },

  //Merchant Management
  listParty: {
    url: boPrefix + "/auth/list_party",
    method: "get",
    headers: {},
  },
  showParty: {
    url: boPrefix + "/auth/show_party",
    method: "get",
    headers: {},
  },
  searchParty: {
    url: boPrefix + "/auth/search_party",
    method: "post",
    headers: {},
  },
  updateParty: {
    url: boPrefix + "/auth/update_party",
    method: "post",
    headers: {},
  },

  //Batch Job
  addNewJob: {
    url: boPrefix + "/batch/add_new_job",
    method: "post",
    headers: {},
  },
  pauseJob: {
    url: boPrefix + "/batch/pause_job",
    method: "post",
    headers: {},
  },
  pauseAllJobs: {
    url: boPrefix + "/batch/pause_all_jobs",
    method: "post",
    headers: {},
  },
  resumeJob: {
    url: boPrefix + "/batch/resume_job",
    method: "post",
    headers: {},
  },
  resumeAllJobs: {
    url: boPrefix + "/batch/resume_all_jobs",
    method: "post",
    headers: {},
  },
  rescheduleCronJob: {
    url: boPrefix + "/batch/reschedule_cronjob",
    method: "post",
    headers: {},
  },
  findAllJobs: {
    url:  boPrefix + "/batch/find_all_jobs",
    method: "get",
    headers: {},
  },
  getAllNames: {
    url: boPrefix + "/batch/get_all_names",
    method: "get",
    headers: {},
  },
  //Deposit Management
  depositListing: {
    url: boPrefix + "/view_deposit_listing",
    method: "post",
    headers: {},
  },
  //Black Listing
  addBlackList: {
    url: boPrefix + "/add_blacklist",
    method: "post",
    headers: {},
  },
  removeBlackList: {
    url: boPrefix + "/remove_blacklist",
    method: "post",
    headers: {},
  },
  searchBlackList: {
    url: boPrefix + "/search_blacklist",
    method: "post",
    headers: {},
  },
  searchBlackListHistory: {
    url: boPrefix + "/search_blacklist_history",
    method: "post",
    headers: {},
  },
  //Fraud Listing
  searchFraud: {
    url: boPrefix + "/search_fraud",
    method: "post",
    headers: {},
  },
  //System Configuration
  loadGlobalSetting: {
    url: boPrefix + "/load_global_setting",
    method: "get",
    headers: {},
  },
  viewMatchingConfig: {
    url: boPrefix + "/view_matching_config",
    method: "get",
    headers: {},
  },
  updateMatchingConfig: { 
    url: boPrefix + "/update_matching_config",
    method: "post",
    headers: {},
  },
};

export default api;
