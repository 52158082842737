import { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { useHistory } from "react-router";
import user from "stores/user";

function Index() {
  const history = useHistory();

  useEffect(() => {
    if (user.username) {
      user.menuTabs = [user.orderKeyName];
      user.menuSettings.selectedKeys = [user.orderName];
      return history.push("/orderMgm");
    }
    history.push("/login");
  });
  return <></>;
}

export default observer(Index);
