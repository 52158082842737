import "./loader.css";

const Loader = () => {
  return (
    <div
      style={{
        width: "100%",
        height: "100vh",
        /* background:'rgba(100,100,100,0.3)', */
        position: "fixed",
        left: 0,
        top: 0,
        zIndex: 9999,
      }}
    >
      <div className="sk-circle">
        <div className="sk-circle1 sk-child"></div>
        <div className="sk-circle2 sk-child"></div>
        <div className="sk-circle3 sk-child"></div>
        <div className="sk-circle4 sk-child"></div>
        <div className="sk-circle5 sk-child"></div>
        <div className="sk-circle6 sk-child"></div>
        <div className="sk-circle7 sk-child"></div>
        <div className="sk-circle8 sk-child"></div>
        <div className="sk-circle9 sk-child"></div>
        <div className="sk-circle10 sk-child"></div>
        <div className="sk-circle11 sk-child"></div>
        <div className="sk-circle12 sk-child"></div>
      </div>
    </div>
  );
};

export default Loader;
