/* eslint-disable import/no-anonymous-default-export */
import { updateSuccess, createSuccess } from "components/feedback";
import customError from "components/customError";
import { makeAutoObservable } from "mobx";
import api from "service/api";
import request from "service/request";
import common from "stores/common";
import moment from "moment";
import user from "stores/user";
import i18n from "stores/i18n";

export default new (class Home {
  constructor() {
    makeAutoObservable(this);
  }

  fraudColumns = [
    {
      dataIndex: "depositId",
      title: i18n.getText("depositId"),
      width: 10,
    },
    {
      dataIndex: "hashedMemId",
      title: i18n.getText("hashedMemId"),
      width: 10,
    },
    {
      dataIndex: "brand",
      title: i18n.getText("brand"),
      width: 10,
    },
    {
      dataIndex: "realName",
      title: i18n.getText("realName"),
      width: 10,
    },
    {
      dataIndex: "createdBy",
      title: i18n.getText("createdBy"),
      width: 10,
    },
    {
      dataIndex: "createdAtString",
      title: i18n.getText("createdAt"),
      width: 10,
    },
    {
      dataIndex: "remarks",
      title: i18n.getText("remarks"),
      width: 10,
    },
  ];

  mainRows = [];
  mainPagination = {
    totalCount: 0,
    page: 1,
    size: 25,
    sortOrders: [],
  };
  rowData = {};

  historyRows = [];
  showDetailsModal = false;
  detailModalData = {};
  showUnpairModal = false;
  showDepNotifyTrsModal = false;
  showGrpCancelModal = false;
  showRetractModal = false;
  showResumeModal = false;
  showReleaseModal = false;
  showImageModal = false;
  showRematchModal = false;
  showCancelModal = false;
  showFraudModal = false;
  showHistoryModal = false;
  historyModalData = {};
  wMoreSwitch = false;
  dMoreSwitch = false;
  showVerifyModal = false;
  verifyModalData = {};
  detailsUploadedFile = "";
  verifyUploadedFile = "";
  priorityLevelVal = "";
  showPriority = false;
  currField = "";
  currOrder = "";
  showEditTransAmt = false;
  showExtendReqTimeoutModal = false;
  confirmTransAmt = "";
  showRealName = false;

  cachedSearch = {};
  dateFormat = "YYYY-MM-DD";
  storeOrdStart = sessionStorage.getItem("ordStart");
  storeOrdEnd = sessionStorage.getItem("ordEnd");
  storeWdOrder = sessionStorage.getItem("wdOrder");
  storeWdStatus = sessionStorage.getItem("wdStatus");
  storeDepOrder = sessionStorage.getItem("depOrder");
  storeDepStatus = sessionStorage.getItem("depStatus");
  storeChannelType = sessionStorage.getItem("depChannelType");
  storeFilter = sessionStorage.getItem("filter");
  storeMemName = sessionStorage.getItem("memName");
  storeMemId = sessionStorage.getItem("memId");
  storeWdType = sessionStorage.getItem("wdType");
  storeBrandName = sessionStorage.getItem("brandName");

  wdStatus = [];
  depStatus = [];
  wdType = [];
  priorityLevelDropdown = [];
  channelTypeDropdown = [];
  brandNameDropdown = [];
  statusLoading = false;
  popupLoading = false;
  channelTypeLoading = false;

  //verify history
  verifyHistoryData = [];
  verifyHistLoading = false;
  verifyHistSelectedImg = null;
  verifyHistSelectedImgTwo = null;

  loadStatus() {
    this.statusLoading = true;
    request(api.orderStatus, {})
      .then((res) => {
        if (res.data.code === 0) {
          let data = res.data.data;
          this.wdStatus = data.W.map((x) => ({ label: x, value: x }));
          this.depStatus = data.D.map((x) => ({ label: x, value: x }));
          this.wdType = data.wdType.map((x) => ({
            label: x,
            value: x,
          }));
        }
      })
      .finally(() => (this.statusLoading = false));
  }

  loadPriorityLevel() {
    return new Promise((resolve) => {
      request(api.getPriorityConfig, {
        params: {},
      })
        .then((res) => {
          if (res.data.code === 0) {
            let data = res.data.data;

            this.priorityLevelDropdown = data.map((x) => ({
              label: x.priorityLevel,
              value: x.priorityLevel,
            }));
          }
        })
        .finally(() => {
          resolve();
        });
    });
  }

  loadChannelType() {
    return new Promise((resolve) => {
      request(api.channelTypeList, {
        params: {},
      })
        .then((res) => {
          if (res.data.data) {
            this.channelTypeDropdown = res.data.data;
          }
        })
        .finally(() => {
          resolve();
        });
    });
  }

  loadBrandName() {
    return new Promise((resolve) => {
      request(api.brandNameList, {
        params: {},
      })
        .then((res) => {
          if (res.data.data) {
            this.brandNameDropdown = res.data.data;
          }
        })
        .finally(() => {
          resolve();
        });
    });
  }

  search(data = this.cachedSearch) {
    common.loading = true;

    //Reset store filter for every search
    this.storeFilter = false;

    //Get start and end date
    const getOrdStart = data.dateRange?.[0]?.format(this.dateFormat);
    const getOrdEnd = data.dateRange?.[1]?.format(this.dateFormat);

    //Get form input
    const getWdOrder = data.wdOrderNo === "" ? "" : data.wdOrderNo;
    const getWdStatus = data.wdStatus === "" ? "" : data.wdStatus;
    const getDepOrder = data.depOrderNo === "" ? "" : data.depOrderNo;
    const getDepStatus = data.depStatus === "" ? "" : data.depStatus;
    const getChannelType =
      data.depChannelType === "" ? "" : data.depChannelType;
    const getMemName = data.memName === "" ? "" : data.memName;
    const getMemId = data.memId === "" ? "" : data.memId;
    const getWdType = data.wdType === "" ? "" : data.wdType;
    const getBrandName = data.brandName === "" ? "" : data.brandName;

    //Get filter (radiobutton)
    const getFilter = data.filter === "" ? "" : data.filter;

    //Set sessionStorage for start and end date
    sessionStorage.setItem("ordStart", getOrdStart);
    sessionStorage.setItem("ordEnd", getOrdEnd);

    //Set sessionStorage for form input, if form input is empty, force set empty value to prevent it from setting default value undefined
    function getOrdStorage(formInput, name) {
      if (formInput) {
        sessionStorage.setItem(name, formInput);
      } else {
        sessionStorage.setItem(name, "");
      }
    }

    getOrdStorage(getWdOrder, ["wdOrder"]);
    getOrdStorage(getWdStatus, ["wdStatus"]);
    getOrdStorage(getDepOrder, ["depOrder"]);
    getOrdStorage(getDepStatus, ["depStatus"]);
    getOrdStorage(getChannelType, ["depChannelType"]);
    getOrdStorage(getFilter, ["filter"]);
    getOrdStorage(getMemName, ["memName"]);
    getOrdStorage(getMemId, ["memId"]);
    getOrdStorage(getWdType, ["wdType"]);
    getOrdStorage(getBrandName, ["brandName"]);

    //Save form input to store
    this.storeOrdStart = getOrdStart;
    this.storeOrdEnd = getOrdEnd;
    this.storeWdOrder = getWdOrder;
    this.storeWdStatus = getWdStatus;
    this.storeDepOrder = getDepOrder;
    this.storeDepStatus = getDepStatus;
    this.storeChannelType = getChannelType;
    this.storeFilter = getFilter;
    this.storeMemName = getMemName;
    this.storeMemId = getMemId;
    this.storeWdType = getWdType;
    this.storeBrandName = getBrandName;

    request(api.orderList, {
      params: {
        wd_order_no: data.wdOrderNo,
        dep_order_no: data.depOrderNo,
        start_date: data.dateRange?.[0]?.format(this.dateFormat),
        end_date: data.dateRange?.[1]?.format(this.dateFormat),
        page_no: this.mainPagination.page,
        page_size: this.mainPagination.size,
        wd_status: data.wdStatus,
        dep_status: data.depStatus,
        sort_orders: this.mainPagination.sortOrders,
        dep_channel_type:
          data.depChannelType === "" || data.depChannelType === undefined
            ? []
            : data.depChannelType,
        filter: data.filter === "n" ? null : data.filter,
        mem_name: data.memName,
        mem_id: data.memId,
        wd_type: data.wdType,
        brand_name:
          data.brandName === "" || data.brandName === undefined
            ? []
            : data.brandName,
      },
    })
      .then((res) => {
        if (res.data.code === 0) {
          this.mainRows = res.data.data;
          this.mainPagination.totalCount = res.data.totalCount;
        }
      })
      .finally(() => (common.loading = false));
  }

  //Search after getting user input, get initial form input to refresh
  searchAfterInput() {
    common.loading = true;

    request(api.orderList, {
      params: {
        wd_order_no: this.storeWdOrder === "" ? null : this.storeWdOrder,
        dep_order_no: this.storeDepOrder === "" ? null : this.storeDepOrder,
        start_date: this.storeOrdStart,
        end_date: this.storeOrdEnd,
        page_no: this.mainPagination.page,
        page_size: this.mainPagination.size,
        wd_status: this.storeWdStatus === "" ? null : this.storeWdStatus,
        dep_status: this.storeDepStatus === "" ? null : this.storeDepStatus,
        sort_orders: this.mainPagination.sortOrders,
        dep_channel_type:
          this.storeChannelType === "" || this.storeChannelType === undefined
            ? []
            : this.storeChannelType,
        filter: this.storeFilter === "n" ? null : this.storeFilter,
        mem_name: this.storeMemName === "" ? null : this.storeMemName,
        mem_id: this.storeMemId === "" ? null : this.storeMemId,
        wd_type: this.storeWdType === "" ? null : this.storeWdType,
        brand_name:
          this.storeBrandName === "" || this.storeBrandName === undefined
            ? []
            : this.storeBrandName,
      },
    })
      .then((res) => {
        if (res.data.code === 0) {
          this.mainRows = res.data.data;
          this.mainPagination.totalCount = res.data.totalCount;
        }
      })
      .finally(() => (common.loading = false));
  }

  doExport(data = this.cachedSearch) {
    common.exporting = true;
    request(api.orderExport, {
      params: {
        wd_order_no: data.wdOrderNo,
        dep_order_no: data.depOrderNo,
        start_date: data.dateRange?.[0].format(this.dateFormat),
        end_date: data.dateRange?.[1].format(this.dateFormat),
        page_no: 1,
        page_size: 9999999,
        wd_status: data.wdStatus,
        dep_status: data.depStatus,
        dep_channel_type:
          data.depChannelType === "" || data.depChannelType === undefined
            ? []
            : data.depChannelType,
        brand_name:
          data.brandName === "" || data.brandName === undefined
            ? []
            : data.brandName,
      },
    })
      .then((res) => {
        if (res.data.code === 0) {
          common.jsonExportCsv(res.data.data, [], {
            body: {
              timeSpent: (cell) => Math.round(parseInt(cell) / 1000),
            },
          });
        }
      })
      .finally(() => (common.exporting = false));
  }

  doExportUserAction(data = this.cachedSearch) {
    common.exportingUserAction = true;
    request(api.orderExportUserAction, {
      params: {
        wd_order_no: data.wdOrderNo,
        dep_order_no: data.depOrderNo,
        start_date: data.dateRange?.[0].format(this.dateFormat),
        end_date: data.dateRange?.[1].format(this.dateFormat),
        page_no: 1,
        page_size: 9999999,
        wd_status: data.wdStatus,
        dep_status: data.depStatus,
        dep_channel_type:
          data.depChannelType === "" || data.depChannelType === undefined
            ? []
            : data.depChannelType,
        brand_name:
          data.brandName === "" || data.brandName === undefined
            ? []
            : data.brandName,
      },
    })
      .then((res) => {
        if (res.data.code === 0) {
          common.jsonExportCsv(res.data.data, [], {
            body: {
              timeSpent: (cell) => Math.round(parseInt(cell) / 1000),
            },
          });
        }
      })
      .finally(() => (common.exportingUserAction = false));
  }

  orderUnpair(data) {
    common.updating = true;
    request(api.orderUnpair, {
      form: {
        matchId: this.rowData.id,
        remarks: data.remarks ? data.remarks : "",
      },
    })
      .then((res) => {
        if (res.data.code === 0) {
          this.showUnpairModal = false;
          updateSuccess();
          this.search();
        }
      })
      .finally(() => (common.updating = false));
  }

  depNotifyTrs(data) {
    common.updating = true;
    request(api.depNotifyTrs, {
      form: {
        matchId: this.rowData.id,
        remarks: data.remarks ? data.remarks : "",
      },
    })
      .then((res) => {
        //if (res.data.code === 0) {
        if (res.data.code === 0 || res.data.code === 40002) {
          this.showDepNotifyTrsModal = false;
          updateSuccess();
          this.search();
        }
      })
      .finally(() => (common.updating = false));
  }

  depNotifyTrsWdOnHold(data) {
    common.updating = true;
    request(api.depNotifyTrsWdOnHold, {
      form: {
        matchId: this.rowData.id,
        remarks: data.remarks ? data.remarks : "",
      },
    })
      .then((res) => {
        //if (res.data.code === 0) {
        if (res.data.code === 0 || res.data.code === 40002) {
          this.showDepNotifyTrsModal = false;
          updateSuccess();
          this.search();
        }
      })
      .finally(() => (common.updating = false));
  }

  withdrawGrpCancel() {
    common.updating = true;
    request(api.withdrawGrpCancel, {
      form: {
        wdId: this.rowData.wdId,
      },
    })
      .then((res) => {
        if (res.data.code === 0) {
          this.showGrpCancelModal = false;
          updateSuccess();
          this.search();
        }
      })
      .finally(() => (common.updating = false));
  }

  withdrawRetract(data) {
    common.updating = true;
    request(api.withdrawGrpCancel, {
      form: {
        wdId: this.rowData.wdId,
        remarks: data.remarks ? data.remarks : "",
      },
    })
      .then((res) => {
        if (res.data.code === 0) {
          this.showRetractModal = false;
          updateSuccess();
          this.search();
        }
      })
      .finally(() => (common.updating = false));
  }

  withdrawResume(data) {
    common.updating = true;
    request(api.withdrawResume, {
      form: {
        wdId: this.rowData.wdId,
        remarks: data.remarks ? data.remarks : "",
      },
    })
      .then((res) => {
        if (res.data.code === 0) {
          this.showResumeModal = false;
          updateSuccess();
          this.search();
        }
      })
      .finally(() => (common.updating = false));
  }

  withdrawRelease(data) {
    common.updating = true;
    request(api.withdrawRelease, {
      form: {
        wdId: this.rowData.wdId,
        remarks: data.remarks ? data.remarks : "",
      },
    })
      .then((res) => {
        if (res.data.code === 0) {
          this.showReleaseModal = false;
          updateSuccess();
          this.search();
        }
      })
      .finally(() => (common.updating = false));
  }

  openVerify() {
    this.popupLoading = true;
    request(api.depTransOpen, {
      form: {
        matchId: this.rowData.id,
      },
    })
      .then((res) => {
        if (res.data.data) {
          this.verifyModalData = res.data.data;
        }
      })
      .finally(() => (this.popupLoading = false));
  }

  openDetails() {
    this.popupLoading = true;
    request(api.depReview, {
      form: {
        matchId: this.rowData.id,
      },
    })
      .then((res) => {
        if (res.data.data) {
          this.detailModalData = res.data.data;
        }
      })
      .finally(() => (this.popupLoading = false));
  }

  openHistory() {
    this.popupLoading = true;
    request(api.viewMatchHistoryDetails, {
      params: {
        wdPartyOrderNo: this.rowData.wdOrderNo,
      },
    })
      .then((res) => {
        if (res.data.data) {
          this.historyModalData = res.data.data;
          this.historyRows = res.data.data.body;
        }
      })
      .finally(() => (this.popupLoading = false));
  }

  listDepositorHistory(matchId, nameState) {
    this.verifyHistLoading = true;
    request(api.listDepositorHistory, {
      form: {
        matchId: matchId,
        byRealName: nameState,
      },
    })
      .then((res) => {
        if (res.data.data) {
          this.verifyHistoryData = res.data.data;
        }
      })
      .finally(() => (this.verifyHistLoading = false));
  }

  verifyApprove(data) {
    common.updating = true;
    request(api.depTransVerify, {
      form: {
        matchId: this.rowData.id,
        transAmt: this.verifyModalData.actualTransAmt,
        comments: data.comments ? data.comments : "",
      },
    })
      .then((res) => {
        if (res.data.code === 0) {
          this.showVerifyModal = false;
          updateSuccess();
          this.search();
        }
      })
      .finally(() => (common.updating = false));
  }

  verifyReject(data) {
    common.updating = true;
    request(api.depTransFake, {
      form: {
        matchId: this.rowData.id,
        comments: data.comments,
      },
    })
      .then((res) => {
        if (res.data.code === 0) {
          this.showVerifyModal = false;
          updateSuccess();
          this.search();
        }
      })
      .finally(() => (common.updating = false));
  }

  verifyOnHold(data) {
    common.updating = true;
    request(api.withdrawOnHold, {
      form: {
        matchId: this.rowData.id,
        remarks: data.comments ? data.comments : "",
      },
    })
      .then((res) => {
        //if (res.data.code === 0) {
        if (res.data.code === 0 || res.data.code === 40002) {
          this.showVerifyModal = false;
          updateSuccess();
          this.search();
        }
      })
      .finally(() => (common.updating = false));
  }

  verifyResume(data) {
    common.updating = true;
    request(api.withdrawResumeCheck, {
      form: {
        matchId: this.rowData.id,
        remarks: data.comments ? data.comments : "",
      },
    })
      .then((res) => {
        if (res.data.code === 0) {
          this.showVerifyModal = false;
          updateSuccess();
          this.search();
        }
      })
      .finally(() => (common.updating = false));
  }

  cancelMatch(data) {
    common.updating = true;
    request(api.cancelMatch, {
      form: {
        matchId: this.rowData.id,
        remarks: data.remarks ? data.remarks : "",
      },
    })
      .then((res) => {
        // if (res.data.code === 0) {
        if (res.data.code === 0 || res.data.code === 40002) {
          this.showCancelModal = false;
          updateSuccess();
          this.search();
        }
      })
      .finally(() => (common.updating = false));
  }

  rematch(data) {
    common.updating = true;
    request(api.rematch, {
      form: {
        matchId: this.rowData.id,
        wdId: this.rowData.wdId,
        depId: this.rowData.depId,
        remarks: data.remarks ? data.remarks : "",
      },
    })
      .then((res) => {
        if (res.data.code === 0) {
          this.showRematchModal = false;
          updateSuccess();
          this.search();
        }
      })
      .finally(() => (common.updating = false));
  }

  informPssOfFraud(data) {
    common.updating = true;

    request(api.informPssOfFraud, {
      form: {
        depPartyOrderNo: this.rowData.depOrderNo,
        depPartyName: this.rowData.depMerCode,
        brand: this.detailModalData.depBrand,
        remarks: data.remarks ? data.remarks : "",
      },
    })
      .then((res) => {
        //Check data for true/false instead
        if (res.data.data === true) {
          this.showDetailsModal = false;
          this.showFraudModal = false;
          updateSuccess({
            message: `Fraud Reported`,
          });
          this.search();
        } else {
          customError("Error reporting this order as a fraud");
        }
      })
      .finally(() => (common.updating = false));
  }

  informPssOfFraudRejected(data) {
    common.updating = true;

    request(api.informPssOfFraud, {
      form: {
        depPartyOrderNo: this.rowData.depOrderNo,
        depPartyName: this.rowData.depMerCode,
        brand: this.verifyModalData.depBrand,
        remarks: data.comments,
      },
    })
      .then((res) => {
        if (res.data.data === true) {
          this.showVerifyModal = false;
          updateSuccess({
            message: `Rejected As Fraud`,
          });
          this.search();
        } else {
          //customError("Error rejecting this order as a fraud");
          customError("拒绝此订单作为欺诈出现错误");
        }
      })
      .finally(() => (common.updating = false));
  }

  verifyRejectFraud(data) {
    common.updating = true;
    request(api.depTransFake, {
      form: {
        matchId: this.rowData.id,
        comments: data.comments,
      },
    })
      .then((res) => {
        // if (res.data.code === 0) {
        //   this.informPssOfFraudNoAlert();
        //   this.showVerifyModal = false;
        //   updateSuccess({
        //     message: `Rejected As Fraud`,
        //   });
        //   this.search();
        // }

        if (res.data.code === 0) {
          this.informPssOfFraudRejected(data);
        }
      })
      .finally(() => (common.updating = false));
  }

  updateTransAmt(data) {
    common.updating = true;
    request(api.updateTransAmt, {
      form: {
        matchId: this.rowData.id,
        transAmt: data.transAmt,
        remarks: data.remarks ? data.remarks : "",
      },
    })
      .then((res) => {
        if (res.data.code === 0) {
          this.showVerifyModal = false;
          updateSuccess();
          this.search();
          this.showVerifyModal = true;
        }
      })
      .finally(() => (common.updating = false));
  }

  extendOrderTo(data) {
    common.updating = true;
    request(api.extendOrderTo, {
      params: {
        matchId: this.rowData.id,
        duration: data.duration,
        admId: user.adminID,
      },
    })
      .then((res) => {
        if (res.data.code === 0) {
          this.showExtendReqTimeoutModal = false;
          updateSuccess({
            message: `Request Timeout Extended`,
          });
          this.search();
        }
      })
      .finally(() => (common.updating = false));
  }

  extendCheck() {
    common.updating = true;
    request(api.extCheck, {
      params: {
        matchId: this.rowData.id,
      },
    })
      .then((res) => {
        if (res.data.code === 0) {
          updateSuccess({
            message: res.data.data,
          });
          this.search();
        }
      })
      .finally(() => (common.updating = false));
  }

  //To clear all session items after logout
  clearAllItems() {
    this.mainRows = [];
    this.mainPagination.totalCount = 0;
    this.mainPagination.page = 1;
    this.mainPagination.size = 25;

    //reset order mgm form input
    this.storeWdOrder = "";
    this.storeWdStatus = "";
    this.storeDepOrder = "";
    this.storeDepStatus = "";
    this.storeOrdStart = moment(new Date()).format("YYYY-MM-DD");
    this.storeOrdEnd = moment(new Date()).format("YYYY-MM-DD");
    this.storeFilter = "";
    this.storeChannelType = "";
    this.storeMemName = "";
    this.storeMemId = "";
    this.storeWdType = "";

    sessionStorage.clear();
  }

  //Details Modal - Upload File
  detailsUploadFile(file) {
    common.updating = true;

    request(api.fileUpload, {
      form: {
        file: file,
        merId: this.detailModalData.depMerId,
        memName: this.rowData.depMemName,
      },
    })
      .then((res) => {
        switch (res.data.code) {
          case 1:
            //Set file data
            this.detailsUploadedFile = res.data.data;

            //Invoke api
            this.detailsSendFile();

            //Close modal
            this.showDetailsModal = false;

            //Success msg
            createSuccess("msg_code_1");

            //Reopen modal
            this.showDetailsModal = true;
            break;
          case -1002:
            customError("文件格式不支持");
            break;
          case -1:
            customError("请正确选择要上传的文件");
            break;
          default:
        }
      })
      .finally(() => (common.updating = false));
  }

  //Verify Modal - Upload File
  verifyUploadFile(file) {
    common.updating = true;

    request(api.fileUpload, {
      form: {
        file: file,
        merId: this.verifyModalData.depMerId,
        memName: this.rowData.depMemName,
      },
    })
      .then((res) => {
        switch (res.data.code) {
          case 1:
            //Set file data
            this.verifyUploadedFile = res.data.data;

            //Invoke api
            this.verifySendFile();

            //Close modal
            this.showVerifyModal = false;

            //Success msg
            createSuccess("msg_code_1");

            //Reopen modal
            this.showVerifyModal = true;
            break;
          case -1002:
            customError("文件格式不支持");
            break;
          case -1:
            customError("请正确选择要上传的文件");
            break;
          default:
        }
      })
      .finally(() => (common.updating = false));
  }

  //Details Modal Send File
  detailsSendFile() {
    common.updating = true;

    request(api.depTransVideo, {
      form: {
        matchId: this.rowData.id,
        videoUri: this.detailsUploadedFile,
      },
    }).finally(() => (common.updating = false));
  }

  //Verify Modal Send File
  verifySendFile() {
    common.updating = true;

    request(api.depTransVideo, {
      form: {
        matchId: this.rowData.id,
        videoUri: this.verifyUploadedFile,
      },
    }).finally(() => (common.updating = false));
  }
})();
