import { Modal } from "antd";
import Icon from "components/icon";
import i18n from "stores/i18n";

//This function takes in 2 parameters
//1) A callback function (NEEDS TO RETURN A PROMISE) to be executed if the user selects "YES"
//2) A string for the title
export default function confirmDelete(funct, title, props) {
  return new Promise((resolveAll, rejectAll) => {
    Modal.confirm({
      title: title || i18n.getText("confirmDelete"),
      icon: <Icon type="ExclamationCircleOutlined" />,
      onOk() {
        return new Promise((resolve, reject) => {
          //Check whether function returns a promise
          try {
            //Call function (if it exists), while checking if it returns a promise
            let isProm = funct?.()
              ?.then((res) => resolve(res))
              .catch(() => {
                //Resolve to CLOSE the pop up
                //resolve();

                //Reject to NOT CLOSE the pop up
                reject();
              });

            //Not a promise, just resolve it
            if (isProm instanceof Promise === false) {
              resolve();
            }
          } catch (err) {
            reject(err);
          }
        }).finally(() => resolveAll());
      },
      onCancel: () => {
        //Special response "onlyClose" to indicate its not a success but not a failure as well, we just want to close the window
        //To be used in combination with CustomSwitch such that the switch is NOT toggled
        resolveAll("onlyClose");
      },
      okType: "danger",
      okText: i18n.getText("yes"),
      cancelText: i18n.getText("no"),
      centered: true,
      zIndex: 2000,
      ...props,
    });
  });
}
