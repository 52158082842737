import sha1 from "sha1";
import sha256 from "sha256";

//Hash function, 2 parameters
// algorithm:string - the type of hash algorithm we want to use
// plainText:string - the string we would like to hash
const hash = (algorithm, plainText) => {
  switch (algorithm) {
    case "sha1":
      return sha1(plainText);
    case "sha256":
      return sha256(plainText);
    default:
      return plainText;
  }
};

//Debounce function, 3 parameters
// callback:function - callback function that runs AFTER the timeout has elapsed
// timeout:number - the time in milliseconds before the callback function will be run
// (OPTIONAL)startFunct:function - A function to be called at the start even before the timer starts
let timer = null;
function debounce(callback, timeout = 1500, startFunct) {
  startFunct?.();
  clearTimeout(timer);
  timer = setTimeout(() => {
    callback();
  }, timeout);
}

//Pass in a camel case string, return a sentence with first character of every word capitalized
// Eg. someNewTitle => Some New Title
function camelToTitle(camelCase) {
  if (camelCase) {
    let addSpace = camelCase.replace(/([A-Z])/g, " $1");
    return addSpace[0].toUpperCase() + addSpace.slice(1);
  }
  return "";
}

//Pass in any text with a delimiter (optional) and convert it to Title Case
// Eg. some-new-title => Some New Title
// Eg. some_new_title => Some New Title
function toTitle(text, delimiter = " ") {
  return text
    .toLowerCase()
    .split(delimiter)
    .map((word) => word[0].toUpperCase() + word.slice(1))
    .join(" ");
}

//Plays music from a sound file, 2 parameters
// src:string - the source location of the sound file
// loop:boolean - boolean whether the audio should be looped
function playSound(src, loop = false) {
  let sound = document.createElement("audio");
  sound.src = src;
  sound.loop = loop;
  sound.play();
}

//Copy function, uses newer web API version, revert to previous version if unsupported on user's browser
function copyText(text) {
  var listener = (e) => {
    e.preventDefault();
    e.clipboardData.setData("text/plain", text);
  };
  document.addEventListener("copy", listener);
  document.execCommand("copy");
  document.removeEventListener("copy", listener);
}

function appendOldVals(oldVals) {
  let tempObj = {};
  if (oldVals) {
    for (const key in oldVals) {
      tempObj[key + "Old"] = oldVals[key];
    }
  }
  return tempObj;
}

export {
  debounce,
  hash,
  camelToTitle,
  playSound,
  toTitle,
  copyText,
  appendOldVals,
};
