import { makeAutoObservable } from "mobx";
import { persistence, StorageAdapter } from "mobx-persist-store";
import { readStore, writeStore } from "stores/persist";
import en from "i18n/en";
import ch from "i18n/ch";

//Antd Translations
import enUS from "antd/lib/locale/en_US";
import zhCN from "antd/lib/locale/zh_CN";
import "moment/locale/zh-cn";

class I18nStore {
  constructor() {
    makeAutoObservable(this);
  }

  language = "en";

  list = {
    en,
    ch,
  };

  get antLang() {
    switch (this.language) {
      case "en":
        return enUS;
      case "ch":
        return zhCN;
      default:
        return {};
    }
  }

  changeLanguage(language) {
    this.language = language;
  }

  getText(textCode) {
    if (this.language === "en") {
      return this.list[this.language][textCode];
    }
    //If the other languages to not have the required translations, use english file as backup
    return this.list[this.language]?.[textCode] || this.list["en"][textCode];
  }
}

export default persistence({
  name: "I18nStore",
  properties: ["language"],
  adapter: new StorageAdapter({ read: readStore, write: writeStore }),
  reactionOptions: { delay: 200 },
})(new I18nStore());
