//NEWEST FILE AS OF 7th DEC, MIGRATE FROM FORMMODAL
import { Form, Button, Skeleton } from "antd";
import { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import common from "stores/common";
import i18n from "stores/i18n";
import CustomModal from "./CustomModal";
import confirmDelete from "components/confirmDelete";
//import customError from "components/customError";

//Minimum Prop Requirements to use this component
// visible - A boolean to decide whether or not to show the Modal
// onCancel - A Function to be called when modal is closed

//Optional Props (Common Usage)
//Title
//modalType - Currently either "add" or "update"
//data - auto populate fields with default data
//onSubmit - Function to call when submit button is clicked (toggle common.updating to ensure button loads)

//noDisable - Allows user to submit, but does not allow OR need to change fields
//viewOnly - View only, no submit button

//Loading
//pageLoading - Used for showing full page loading while data is being populated
//buttonLoading - Used for showing submit button loading while waiting for API response (common.updating is default)

//Optional Props
//keyCol/valCol - easier method to set key/val columns
//formProps - all form props will be passed through this
//getForm - Pass in useForm to manually gain control of the form data
//noConfirmation - no confirmation popup when modal is closed

export default observer(function CustomFormModal({
  modalType,
  onSubmit,
  onCancel,
  keyCol,
  valCol,
  data,
  getForm,
  formProps,
  viewOnly,
  children,
  noDisable,
  pageLoading,
  buttonLoading,
  noConfirmation,
  layout,
  ...modalProps
}) {
  const [form] = Form.useForm();
  const [edited, setEdited] = useState(false);
  const formItemLayout = {
    labelCol: {
      span: keyCol || 10,
    },
    wrapperCol: {
      span: valCol || 10,
    },
  };

  useEffect(() => {
    //Everytime data changes, autofill the form with the new data
    getForm ? getForm.setFieldsValue(data) : form.setFieldsValue(data);
  }, [data]);

  useEffect(() => {
    //When Modal Closes
    if (modalProps.visible === false) {
      getForm ? getForm.resetFields() : form.resetFields(); //Clear Form
      setEdited(false); //Reset "edited"
    }
  }, [modalProps.visible]);

  const getTitle = () => {
    switch (modalType) {
      case "add":
      case "create":
        return i18n.getText("create");
      case "update":
      case "edit":
        return i18n.getText("edit");
      default:
        return String.fromCharCode(160);
    }
  };

  function RenderSubmit() {
    const SubmitName = () => {
      switch (modalType) {
        case "add":
        case "create":
          return i18n.getText("add");
        case "update":
        case "edit":
          return i18n.getText("update");
        default:
          return i18n.getText("submit");
      }
    };
    return (
      <Button
        disabled={!edited && !noDisable}
        type="primary"
        loading={buttonLoading || common.updating}
        onClick={() =>
          form
            .validateFields()
            .then((values) => {
              //Convert all undefined values into a null value
              let newVals = Object.fromEntries(
                Object.entries(values).map((item) => [item[0], item[1]])
              );

              //Check if function returns a promise, if so we will close the modal if it resolves successfully
              //If not, then the function needs to handle to closing of the modal itself
              let isProm = onSubmit({ ...data, ...newVals });
              if (isProm instanceof Promise === true) {
                isProm.then(() => onCancel());
              }
            })
            .catch((info) => {
              console.error("验证失败:", info);
             // customError("验证失败:" + info);
            })
        }
      >
        <span>
          <SubmitName />
        </span>
      </Button>
    );
  }

  return (
    <CustomModal
      title={getTitle(modalType)}
      centered
      footer={null}
      onCancel={() => {
        if (edited && !noDisable) {
          confirmDelete(() => {
            onCancel();
          }, i18n.getText("confirmCloseModal"));
        } else {
          onCancel();
        }
      }}
      confirmLoading={buttonLoading || common.updating}
      {...modalProps}
    >
      <Skeleton active loading={pageLoading}>
        <Form
          form={getForm || form}
          onFieldsChange={() => {
            !noConfirmation && setEdited(true);
          }}
          layout={layout || ""}
          {...formItemLayout}
          {...formProps}
        >
          {children}
          {!viewOnly && (
            <Form.Item label=" " colon={false}>
              <RenderSubmit />
            </Form.Item>
          )}
        </Form>
      </Skeleton>
    </CustomModal>
  );
});
