let enLabels = {
  //Main Menu Names
  mm_home: "Home",
  mm_userManagement: "User Mgm",
  mm_audit: "Audit",
  mm_memberManagement: "Member Mgm",
  mm_messageCenter: "Message Center",
  mm_promotionManagement: "Promo Mgm",
  mm_lotteryManagement: "Lott Mgm",
  mm_dashboard: "Dashboard",
  mm_riskManagement: "Risk Mgm",
  mm_reports: "Reports",
  mm_nonLotteryGame: "Non Lott Game",
  mm_miniGame: "Mini Game",
  mm_payment: "Payment",
  mm_merchantManagement: "Merchant Mgm",
  mm_BatchJob: "Batch Job",
  mm_depositManagement: "Deposit Management",
  mm_blackListing: "Black Listing",
  mm_fraudListing: "Fraud Listing",

  //Sub Menu Names
  //User Mgm
  sm_home: "Demo Table",
  sm_userListing: "User Listing",
  sm_roleListing: "Role Listing",
  sm_opsRoleManagement: "Ops Role Mgm",
  sm_merchantListing: "Merchant Listing",
  sm_batchJobListing: "Batch Job Listing",
  sm_depositListing: "Deposit Listing",

  //Audit
  sm_auditListing: "Audit Listing",

  //Member Mgm
  sm_memberListing: "Mem Listing",
  sm_memberGroupListing: "Mem Grp Listing",
  sm_messageInbox: "Msg Inbox",
  sm_blockMemberReport: "Blk Mem Rpt",
  sm_memberAdjustmentReport: "Mem Adj Rpt",
  sm_memGrpAutoTrans: "Mem Grp Auto Trans",
  sm_memberLabels: "Mem Labels",
  sm_topWinnerReport: "Top Winner Rpt",

  //Msg Center
  sm_messageListing: "Msg Listing",
  sm_sendMessage: "Send Msg",
  sm_messageTemplate: "Msg Template",
  sm_bulkMessageInfo: "Bulk Msg Info",
  sm_announcementListing: "Annc Listing",
  sm_bankMaintenanceAnnouncement: "Bank Maint Annc",

  //Promo Mgm
  sm_promotionListing: "Promo Listing",

  //Lott Mgm
  sm_autoApprovalSetting: "Auto Appr Set",
  sm_drawManagement: "Draw Mgm",
  sm_drawHistory: "Draw Hist",
  sm_drawPeriodManagement: "Draw Period Mgm",
  sm_lotterySetting: "Lott Set",
  sm_lotteryGameSetting: "Lott Game Set",
  sm_drawHeatMap: "Draw Heat Map",
  sm_lotteryPerformanceReport: "Lott Perf Rpt",
  sm_orderManagement: "Order Mgm",

  //Dashboard
  sm_mainDashboard: "Main Dashboard",
  sm_riskManagementDashboard: "Risk Mgm Dashboard",
  sm_prodoctPerformanceDashboard: "Prod Perf Dashboard",
  sm_withdrawKpiDashboard: "Withdraw KPI Dashboard",

  //Risk Mgm
  sm_withdrawManagementSetting: "Withdraw Mgm Set",
  sm_lotteryOrderDrawInspection: "Lott Order Draw Insp",
  sm_moneyLaunderingInspection: "Money Laund Insp",
  sm_moneyLaunderingInspectionSetting: "Money Laund Insp Set",

  //Reports
  sm_kpiReport: "KPI Rpt",
  sm_activeMemberReport: "Act Mem Rpt",

  //Non Lott Games
  sm_slotGames: "Slot Games",
  sm_productListing: "Prod Listing",
  sm_jackpotListing: "Jackpot Listing",
  sm_jackpotApproval: "Jackpot Appr",
  sm_unclaimedJackpotWinners: "Unclm Jackpot Wnrs",
  sm_jackpotDrawHistory: "Jackpot Draw Hist",
  sm_jackpotWinnerHistory: "Jackpot Winner Hist",
  sm_jackpotSummaryReport: "Jackpot Sum Rpt",

  //Mini Game
  sm_miniGameDrawManagement: "Mini Game Draw Mgm",
  sm_miniGameOrderManagement: "Mini Game Order Mgm",
  sm_miniGameReport: "Mini Game Rpt",

  //Payment
  sm_paymentAudit: "Payment Audit",

  //Black Listing
  sm_blackListing: "Black Listing",
  sm_blackListHistory: "Black List History",

  //Fraud
  sm_fraudListing: "Fraud Listing",

  //Lottery Code
  CP: "LOTT",
  ZR: "Live", //Used to be Live Casino, shortened it (client request)
  TY: "Sports",
  DZ: "Slots", //Used to be Slot Machine, shortened it (client request)
  QP: "Others",
  DZQP: "Slots/Others",

  //Status
  allStatus: "All Status",
  status_A: "Active",
  status_B: "Blocked",
  status_I: "Inactive",
  status_S: "Suspended",
  status_R: "Active Reject Withdrawal",
  status_H: "Active Hold Withdrawal",
  status_D: "Deleted",
  status_F: "Frozen",
  status_V: "Investigate",
  status_P: "Pending",
  status_C: "Cleared",
  status_NC: "Not Cleared",

  //Mini Game status
  mini_status_active: "Active",
  mini_status_win: "Win",
  mini_status_lose: "Lose",
  mini_status_draw: "Draw",
  mini_status_void: "Void",

  //Button Names
  search: "Search",
  createUser: "Create User",
  expand: "Expand",
  collapse: "Collapse",

  //User Feedback Phrases
  accountInUse: "This account is currently in use.",
  logUserOut: "Proceed to log the existing user out?",
  success: "Success!",
  confirmDelete: "Confirm Delete?",
  createSuccess: "Creation Success",
  updateSuccess: "Update Success",
  deleteSuccess: "Deletion Success",
  resetPwSuccess: "Reset Password Success",
  msgSent: "Msg Sent",
  confirmCloseModal: "You have unsaved changes, confirm close?",
  bulkMsgCancelConfirm: "Confirm to cancel bulk message task now?",
  repairRequestStarted: "Repair Request Started",
  requestCancelled: "Request Cancelled",
  wfCreated: "Workflow Task Created",
  confirmCancelOrder: "Confirm Cancel Order?",
  switchToggledOn: "Switch Toggled ON",
  switchToggledOff: "Switch Toggled OFF",
  successfullyClearedMember: "Successfully cleared member",
  successfullyApproved: "Successfully Approved",
  successfullyRejected: "Successfully Rejected",
  pleaseConfirmDetails: "Please Confirm Details",

  //Error Codes
  errorCode: "Error Code",
  //msg_code_500: "No Data",

  msg_code_50001: "请输入正确的用户名",
  msg_code_20048: "Google OTP 错误",
  // msg_code_40000:	"您的账户已被登出",
  msg_code_40004: "无效操作：订单状态不匹配",
  msg_code_40005: "无效操作：请勿重复申请延长时效",
  // 40004	该笔订单无法使用Group Cancel
  // 40004	该笔订单无法被Cancel
  // 40004	该笔订单无法被Unpair
  // 40004	该笔订单无法被Approve
  // 40004	该笔订单无法被Reject
  // 40004	该笔订单无法被ON Hold
  msg_code_40000: "您没有足够权限",
  msg_code_1: "上传成功",
  msg_code_60001:
    "Deposit party order no does not exist or has not yet been completed",
  msg_code_60002:
    "Failed to indicate fraud to PSS, no deposit results with this party order number was found.",
  msg_code_80002: "Could not retrieve history data for this withdraw id",
  msg_code_200701: "无法更新贸易商记录",
  msg_code_200702: "贸易商不存在",
  msg_code_200703: "贸易商名称无效",
  msg_code_200704: "无法删除贸易商记录",
  msg_code_10000: "Maximum System allowed export range is 1 month",

  //Title
  batchApplyGameSettings: "Batch Apply Game Settings",
  masterAutoApprovalSettings: "Master Auto Approval Settings",
  memberGroupAutoApprovalSettings: "Member Group Auto Approval Settings",
  orderInspectionManagement: "Order Inspection Management",
  transferMemberGroup: "Transfer Member Group",
  jackpotUnclaimedWinnerList: "Jackpot Unclaimed Winner List",
  myTasks: "My Tasks",
  infoTasks: "Info Tasks",
  uncollectedDeposits: "Uncollected Deposits",
  withdrawalTasks: "Withdrawal Tasks",
  errorTasks: "Error Tasks",
  logout: "Logout",

  //member status
  m_statusDDA: "Active",
  m_statusDDI: "Inactive",
  m_statusDDSU: "Suspended",
  m_statusDDRW: "Active (Reject Withdrawal)",
  m_statusDDHW: "Active (Hold Withdrawal)",
  m_statusDDF: "Frozen",
  m_statusDDB: "Blocked",
  m_statusDDY: "System Blocked",
  m_statusDDN: "Infected",
  m_statusDDB1: "Blocked 1",
  m_statusDDV: "Investigate",
  m_statusDDL: "Sleep",

  //Member Listing
  totalDeposit: "Total Deposit",
  totalWithdraw: "Total Withdrawal",
  todayDeposit: "Today's Deposit",
  todayWithdrawal: "Today's Withdrawal",
  totalMemberGet: "Member Get $",
  totalAgentGive: "Agent Give $",

  rounding_U: "Up",
  rounding_D: "Down",
  rounding_N: "Nearest",

  m_accountDDP_SB188: "Sportsbook",
  m_accountDDP_CA: "LiveCasino",
  m_accountDDP_LO: "Lottery",
  m_accountDDP_Agile: "Agile",
  m_accountDDP_SSC: "SSC",
  m_accountDDP_EA: "HELE EA",
  m_accountDDP_MGS_SM: "Slot Machine",
  m_accountDDP_GG: "GG",
  m_accountDDP_TCG_AG: "AG", //BOCN-743 - AG
  m_accountDDP_AE: "AE", //BOCN-2267 - AE
  m_accountDDP_TCG_EA: "TCG-EA", //BOCN-1267 - TCG EA
  m_accountDDP_IBC: "IBC",
  m_accountDDP_BSG: "BSG",
  m_accountDDP_KENO: "KENO",
  m_accountDDP_PT: "PT",
  m_accountDDP_KY: "KY", //BOCN-2748 - KY
  m_accountDDP_MT: "MT", //BOCN-2740 - MT
  m_accountDDP_BG: "BG-Live", //BOCN-3103 - BG
  m_accountDDP_CQ9: "CQ9", //BOCN-2588 - CQ9
  m_accountDDP_TCG_WS: "TCG-WS", //BOCN-3169 - TCG WS
  m_accountDDP_TCG_HB: "TCG-HB", //BOCN-3503 - TCG HB
  m_accountDDP_TCG_BBIN: "TCG-BBIN",
  m_accountDDP_TCG_SUNBET: "TCG-SUNBET",
  m_accountDDP_TCG_PNG: "TCG-PNG", //BOCN-3310 - TCG PNG
  m_accountDDP_TCG_PP: "TCG-PP", //BOCN - 3504 PP
  m_accountDDP_GS: "GS", //BOCN-3308 - GS (not a real acct)
  m_accountDDP_BGCARD: "BG-Card",
  m_accountDDP_BGFISHDS: "BG-FishDS",
  m_accountDDP_BGXYFISH: "BG-XYFish",
  m_accountDDP_SBT: "SBTech", //BOCN-3366 - SBTECH
  m_accountDDP_PG: "PG", //BOCN-3652 - PG
  m_accountDDP_XY: "XY", //BOCN-3673 - XY
  m_accountDDP_KX: "KX", //BOCN-4499 - KX
  m_accountDDP_WL_BTI: "WL-BTI", //BOCN-5268 - WL_BTI

  m_accountITM_CA: "Main",
  m_accountITP_SB188: "Sportsbook",
  m_accountITP_Agile: "Agile",
  m_accountITP_SSC: "Lottery",
  m_accountITP_EA: "HELE EA",
  m_accountITP_MGS_SM: "Slot Machine",
  m_accountITP_BSG: "BSG",
  m_accountITP_KENO: "KENO",
  m_accountITP_PT: "PT",
  m_accountITP_TCG_AG: "AG Live", //BOCN-743 - AG
  m_accountITP_AE: "AE", //BOCN-2267 - AE
  m_accountITP_TCG_EA: "TCG-EA", //BOCN-1267 - TCG EA
  m_accountITP_IBC: "IBC", //IBC
  m_accountITP_KY: "KY", //BOCN-2748 - KY
  m_accountITP_MT: "MT", //BOCN-2740 - MT
  m_accountITP_BG: "BG", //BOCN-3103 - BG
  m_accountITP_CQ9: "CQ9", //BOCN-2588 - CQ9
  m_accountITP_TCG_WS: "TCG_WS", //BOCN-3169 - TCG WS
  m_accountITP_TCG_HB: "TCG_HB", //BOCN-3503 - TCG HB
  m_accountITP_TCG_BBIN: "TCG_BBIN",
  m_accountITP_TCG_SUNBET: "TCG_SUNBET",
  m_accountITP_TCG_PNG: "TCG_PNG", //BOCN-3310 - TCG PNG
  m_accountITP_TCG_PP: "TCG_PP", //BOCN - 3504 TCG PP
  m_accountITP_GG: "GG",
  m_accountITP_SBT: "SBTech", //BOCN-3366 - SBTECH
  m_accountITP_PG: "PG", //BOCN-3652 -PG
  m_accountITP_XY: "XY", //BOCN-3673 - XY
  m_accountITP_KX: "KX", //BOCN-4499 - KX
  m_accountITP_WL_BTI: "WL-BTI", //BOCN-5268 - WL_BTI

  pr_productSB188: "Sportsbook",
  pr_productAgile: "Agile",
  pr_productSSC: "Lottery",
  pr_productMMC: "MMC", //CPCN-115 - MMC
  pr_productEA: "HELE EA",
  pr_productMGS_SM: "Slot Machine",
  pr_productGG: "GG",
  pr_productTCG_AG: "AG", //BOCN-743 - AG
  pr_productAE: "AE", //BOCN-2267 - AE
  pr_productTCG_EA: "TCG-EA", //BOCN-1267 - TCG EA
  pr_productTCG_WS: "TCG-WS", //BOCN-3169 - TCG WS
  pr_productTCG_HB: "TCG-HB", //BOCN-3503 - TCG HB
  pr_productTCG_BBIN: "TCG-BBIN",
  pr_productTCG_SUNBET: "TCG-SUNBET",
  pr_productTCG_PNG: "TCG-PNG", //BOCN-3310 - TCG PNG
  pr_productTCG_PP: "TCG-PP", //BOCN- 3504 - TCG_PP
  pr_productIBC: "IBC", //IBC
  pr_productBSG: "BSG",
  pr_productKENO: "KENO",
  pr_productPT: "PT",
  pr_productKY: "KY", //BOCN-2748 - KY
  pr_productMT: "MT", //BOCN-2740 - MT
  pr_productBG: "BG", //BOCN-3103 - BG
  pr_productCQ9: "CQ9", //BOCN-2588 - CQ9
  pr_productGS: "GS", //BOCN-3088 - GS
  pr_productSBT: "SBTech", //BOCN-3366 - SBTECH
  pr_productPG: "PG", //BOCN-3652 - PG
  pr_productXY: "XY", //BOCN-3673 - XY
  pr_productKX: "KX", //BOCN-4499 - KX
  pr_productRT: "ROCKET",
  pr_productBX: "BLINDBOX",
  pr_productMN: "MILLIONAIRE",
  pr_productROCKET: "ROCKET",
  pr_productBLINDBOX: "BLINDBOX",
  pr_productMILLIONAIRE: "MILLIONAIRE",
  pr_productBGCARD: "BG-Card",
  pr_productBGFISHDS: "BG-FishDS",
  pr_productBGXYFISH: "BG-XYFish",
  pr_productWL_BTI: "WL-BTI", //BOCN-5268 - WL_BTI

  //Block Member Report
  applyToFutureCases: "Apply to future cases",
  infectionBlocked: "Infection Blocked",
  infectionHoldWithdraw: "Infection Hold Withdraw",
  disableFundTransfer: "Disable Fund Transfer",
  disableDeposit: "Disable Deposit",
  disableLogin: "Disable Login",

  //Member Adjustment Report
  m_txnTypeDDMEM_ADJ_COMP: "Compensation",
  m_txnTypeDDMEM_ADJ_REB: "Cash Rebate",
  m_txnTypeDDMEM_ADJ_DEP: "Deposit Rebate",
  m_txnTypeDDMEM_ADJ_DED: "Manual Deduction",
  m_txnTypeDDMEM_ADJ_COM: "Profit Share Income",
  m_txnTypeDDMEM_ADJ_WL: "Win-Loss Income",
  m_txnTypeDDMEM_ADJ_MA: "Manual Addition",
  m_txnTypeDDMEM_ADJ_CPD: "Cancelled Promotion Deduction",
  m_txnTypeDDMEM_ADJ_AC: "Advance Credit",
  m_txnTypeDDMEM_ADJ_FWD: "Force Withdraw Deduction",
  m_txnTypeDDMEM_ADJ_FWD_RVS: "Force Withdraw Ded. Reversal",

  //Member Group Auto Transfer
  minRegDays: "Reg Days >=",
  minDepositAmt: "Deposit Amt >=",
  minTurnOver: "T.O >= (%)",
  minTotalDepositRank: "Total Dep Rank >= (%)",

  //Task Audit Report
  task_audit_rpt_A: "Approved",
  task_audit_rpt_R: "Rejected",
  task_audit_rpt_CLAIM: "Claim",
  task_audit_rpt_UNCLAIM: "Unclaim",

  //Payment
  payment_status_P: "Pending",
  payment_status_A: "Approved",
  payment_status_R: "Rejected",
  payment_status_Y: "Paid",
  payment_status_F: "Failed",
  payment_status_I: "Paying",
  payment_status_V: "Reversed",
  payment_status_FC: "Fail Confirmation",
  payment_status_PF: "Pending for Force Approval",
  payment_status_C: "Closed",
  payment_status_N: "New",

  //Workflow
  wf_route_A: "Approve",
  wf_route_R: "Reject",
  wf_route_C: "Close",
  PRIORITY_A_R: 90,
  PRIORITY_D: 80,
  PRIORITY_W: 70,
  PRIORITY_S: 60,
  PRIORITY_P: 60,
  PRIORITY_MM: 50,
  PRIORITY_T: 50,
  PRIORITY_JP: 70,
  m_txnTypeDDMEM_TROC: "Transfer Out",
  m_txnTypeDDMEM_TRIC: "Transfer In",
  m_txnTypeDDA_M_TROC: "Agent Give $",
  m_txnTypeDDA_M_TRIC: "Member Get $",
  m_txnTypeDDMEM_O_FR: "Out from Frozen Acct",
  m_txnTypeDDMEM_I_FR: "In to Frozen Acct",
  m_txnTypeDDAGT_GRP_REBATE: "Agent Group Rebate",
  m_txnTypeDDMEM_MAN_ADD: "Manual Add",
  m_txnTypeDDMEM_WDOC: "Withdraw out from Cash Acct",
  m_txnTypeDDMEM_REBATE: "Rebate",
  m_txnTypeDDAGT_BONUS_PAYOUT: "Agent Bonus Payout",
  m_txnTypeDDMEM_WB_CHARGE: "Withdraw Bank Charge",
  m_txnTypeDDMEM_DB_CHARGE: "Deposit Bank Charge",
  m_txnTypeDDMEM_PROM_BONUS: "Promtion Cost",
  m_txnTypeDDJACKPOT_CLAIM: "Jackpot Winning",

  //Announcement Listing
  annc_S: "Submitted",
  annc_A: "Approved",
  annc_R: "Rejected",
  annc_N: "New",
  annc_M: "Member",
  annc_P: "Public",
  annc_B: "BO Users",
  annc_ANN: "Announcement",
  annc_MA: "Match Announcement",
  annc_ACT: "Activity",

  //Bank Maintainance
  bankMaint_N: "Draft",
  bankMaint_S: "Publish",

  //Promotion listing
  pr_promTypeNC: "No Choice",
  pr_promTypeS: "Select X",
  pr_promTypeC: "Checkpoint",
  pr_promTypeI: "Independent",

  //Lottery
  lott_s1: "Single Draw Win Amount Over",
  lott_s2: "Single Bet Amount",
  lott_s3: "Single Draw Win Amount Multiplier",

  // SSC
  l_gameType1S: "后一直选",
  l_gameType2S: "后二直选",
  l_gameType2C: "后二组选",
  l_gameType2P: "后二分位",
  l_gameType22: "后二包点",
  l_gameType2A: "后二连选",
  l_gameType2D: "后二跨度",
  l_gameType3S: "后三直选",
  l_gameType3M: "后三包点",
  l_gameType33: "后三组三",
  l_gameType36: "后三组六",
  l_gameType3A: "后三连选",
  l_gameType3C: "后三直选组合",
  l_gameType3L: "后三混合组选",
  l_gameTypeBS: "前二直选",
  l_gameTypeBC: "前二组选",
  l_gameTypeBP: "前二分位",
  l_gameTypeB2: "前二包点",
  l_gameTypeBA: "前二连选",
  l_gameTypeBD: "前二跨度",
  l_gameTypeCS: "前三直选",
  l_gameTypeCM: "前三包点",
  l_gameTypeC3: "前三组三",
  l_gameTypeC6: "前三组六",
  l_gameTypeCA: "前三连选",
  l_gameTypeCC: "前三直选组合",
  l_gameTypeCL: "前三混合组选",
  l_gameTypeMS: "中三直选",
  l_gameTypeMM: "中三包点",
  l_gameTypeM3: "中三组三",
  l_gameTypeM6: "中三组六",
  l_gameTypeMA: "中三连选",
  l_gameTypeML: "中三混合组选",
  l_gameType5S: "五星直选",
  l_gameType5C: "五星通选",
  l_gameType5A: "五星连选",
  l_gameType5d: "五星组选120",
  l_gameType5e: "五星组选60",
  l_gameType5f: "五星组选30",
  l_gameType5g: "五星组选20",
  l_gameType5h: "五星组选10",
  l_gameType5i: "五星组选5",
  l_gameType2U: "后二和值",
  l_gameType3U: "后三和值",
  l_gameTypeBU: "前二和值",
  l_gameTypeCU: "前三和值",
  l_gameTypeMU: "中三和值",
  l_gameTypeBB: "前二大小单双",
  l_gameType2B: "后二大小单双",
  l_gameTypeBF: "前二组选不定位胆",
  l_gameTypeBG: "前二直选不定位胆",
  l_gameTypeCH: "前三组选包一胆",
  l_gameTypeCI: "前三组选包二胆",
  l_gameTypeCJ: "前三直选包一胆",
  l_gameTypeCK: "前三直选包二胆",
  l_gameType2F: "后二组选不定位胆",
  l_gameType2G: "后二直选不定位胆",
  l_gameType3H: "后三组选包一胆",
  l_gameType3I: "后三组选包二胆",
  l_gameType3J: "后三直选包一胆",
  l_gameType3K: "后三直选包二胆",
  l_gameTypeMH: "中三组选包一胆",
  l_gameTypeMI: "中三组选包二胆",
  l_gameTypeMJ: "中三直选包一胆",
  l_gameTypeMK: "中三直选包二胆",
  l_gameType5F: "定位胆",
  l_gameType4S: "后四直选",
  l_gameTypeDS: "前四直选",
  l_gameType4d: "四星组选24",
  l_gameType4e: "四星组选12",
  l_gameType4f: "四星组选6",
  l_gameType4g: "四星组选4",
  l_gameTypeBR: "任二直选",
  l_gameType3R: "任三直选",
  l_gameType4R: "任四直选",
  l_gameTypeDa: "万千龙虎",
  l_gameTypeDb: "万百龙虎",
  l_gameTypeDc: "万十龙虎",
  l_gameTypeDd: "万个龙虎",
  l_gameTypeDe: "千百龙虎",
  l_gameTypeDf: "千十龙虎",
  l_gameTypeDg: "千个龙虎",
  l_gameTypeDh: "百十龙虎",
  l_gameTypeDi: "百个龙虎",
  l_gameTypeDj: "十个龙虎",
  l_gameTypeS1: "一帆风顺",
  l_gameTypeS2: "好事成双",
  l_gameTypeS3: "三星报喜",
  l_gameTypeS4: "四季发财",
  l_gameTypeXa: "万位大小单双",
  l_gameTypeXb: "千位大小单双",
  l_gameTypeXc: "百位大小单双",
  l_gameTypeXd: "十位大小单双",
  l_gameTypeXe: "个位大小单双",

  //P35
  l_gameTypeCa: "P3后二直选",
  l_gameTypeCb: "P3后二组选",
  l_gameTypeCc: "P3后二分位",
  l_gameTypeCd: "P3后二连选",
  l_gameTypeCe: "P3后二和值",
  l_gameTypeCF: "P3后二跨度",
  l_gameTypeCG: "P3后二大小单双",
  l_gameTypeCh: "P3不定位胆后二直选",
  l_gameTypeSD: "P3/3D三星包点",
  l_gameTypeED: "P3/3D前二包点",
  l_gameTypeHB: "P3后二包点",
  l_gameTypeHD: "P5/3D后二包点",
  l_gameTypeB3: "P5后三包点",

  // 11 X 5
  l_gameTypeFa: "任选一",
  l_gameTypeFb: "任选二",
  l_gameTypeFc: "任选三",
  l_gameTypeFd: "任选四",
  l_gameTypeFe: "任选五",
  l_gameTypeFf: "任选六",
  l_gameTypeFg: "任选七",
  l_gameTypeFh: "任选八",
  l_gameTypeFO: "定单双",
  l_gameTypeFM: "猜中位",
  l_gameTypeF2: "前二直选",
  l_gameTypeFB: "前二组选",
  l_gameTypeF3: "前三直选",
  l_gameTypeFC: "前三组选",
  l_gameTypeFG: "前三不定位胆",
  l_gameTypeFF: "前三定位胆",
  l_gameTypeTB: "前二组选胆拖",
  l_gameTypeTC: "前三组选胆拖",
  l_gameTypeTb: "任选二胆拖",
  l_gameTypeTc: "任选三胆拖",
  l_gameTypeTd: "任选四胆拖",
  l_gameTypeTe: "任选五胆拖",
  l_gameTypeTf: "任选六胆拖",
  l_gameTypeTg: "任选七胆拖",
  l_gameTypeTh: "任选八胆拖",
  l_gameTypeRa: "后三直选",
  l_gameTypeRb: "后三组选",

  //3D
  l_gameType_3d3S: "三星直选",
  l_gameType_3d3M: "三星包点",
  l_gameType_3d33: "三星组三",
  l_gameType_3d36: "三星组六",
  l_gameType_3d3A: "三星连选",
  l_gameType_3d3L: "三星混合组选",
  l_gameType_3d3F: "三星组选",
  l_gameType_3d3U: "三星和值",
  l_gameType_3d3H: "三星组选包一胆",
  l_gameType_3d3I: "三星组选包二胆",
  l_gameType_3d3J: "三星直选包一胆",
  l_gameType_3d3K: "三星直选包二胆",

  //KENO
  l_gameTypeK5: "五行",
  l_gameTypeK4: "大小单双",
  l_gameTypeKC: "奇偶盘",
  l_gameTypeKU: "上下盘",
  l_gameTypeKB: "大小盘",
  l_gameTypeKS: "单双盘",
  l_gameTypeKa: "任选一",
  l_gameTypeKb: "任选二",
  l_gameTypeKc: "任选三",
  l_gameTypeKd: "任选四",
  l_gameTypeKe: "任选五",

  //K3
  l_gameType_K3Ka: "和值",
  l_gameType_K3Kb: "三不同号",
  l_gameType_K3Kc: "二不同号",
  l_gameType_K3Kd: "三同号单选",
  l_gameType_K3Ke: "三连号单选",
  l_gameType_K3Kf: "三同号通选",
  l_gameType_K3Kg: "三连号通选",
  l_gameType_K3Kh: "二同号单选",
  l_gameType_K3Ki: "二同号复选",
  l_gameType_K3Kj: "大小单双",

  //PK10
  l_gameTypePa: "猜冠军",
  l_gameTypePb: "猜冠亚",
  l_gameTypePc: "猜冠亚季",
  l_gameTypePd: "猜前四",
  l_gameTypePe: "前五定位胆",
  l_gameTypePf: "后五定位胆",
  l_gameTypePg: "冠亚和值",
  l_gameTypePh: "前五大小单双(冠军)",
  l_gameTypePi: "前五大小单双(亚军)",
  l_gameTypePj: "前五大小单双(季军)",
  l_gameTypePk: "前五大小单双(第四名)",
  l_gameTypePl: "前五大小单双(第五名)",
  l_gameTypePm: "后五大小单双(第六名)",
  l_gameTypePn: "后五大小单双(第七名)",
  l_gameTypePo: "后五大小单双(第八名)",
  l_gameTypePp: "后五大小单双(第九名)",
  l_gameTypePq: "后五大小单双(第十名)",
  l_gameTypePr: "冠亚和大小单双",
  l_gameTypePs: "龙虎-冠军VS第十名",
  l_gameTypePt: "龙虎-亚军VS第九名",
  l_gameTypePu: "龙虎-季军VS第八名",
  l_gameTypePv: "龙虎-第四名VS第七名",
  l_gameTypePw: "龙虎-第五名VS第六名",
  l_gameTypePx: "前三和值",
  l_gameTypePy: "后三和值",

  //LUCKY 28
  l_gameTypeLf: "趣味",
  l_gameTypeLs: "单点",

  //HL6HC
  l_gameTypeHS: "特码",
  l_gameTypeH1: "正码1",
  l_gameTypeH2: "正码2",
  l_gameTypeH3: "正码3",
  l_gameTypeH4: "正码4",
  l_gameTypeH5: "正码5",
  l_gameTypeH6: "正码6",
  l_gameTypeHC: "半波",
  l_gameTypeZS: "特肖",
  l_gameTypeZ1: "一肖",
  l_gameTypeZ6: "六肖",
  l_gameTypeHT: "尾数",
  l_gameTypeHA: "总分",
  l_gameType5M: "五不中",
  l_gameType6M: "六不中",
  l_gameType7M: "七不中",
  l_gameType8M: "八不中",
  l_gameType9M: "九不中",
  l_gameTypeTM: "十不中",
  l_gameTypeZA: "快乐二",
  l_gameTypeZB: "快乐三",
  l_gameTypeZC: "快乐四",
  l_gameTypeZD: "快乐五",
  l_gameTypeZE: "前三直",
  l_gameTypeZF: "前三组",
  l_gameTypeZG: "二连直",
  l_gameTypeZH: "二连组",
  l_gameTypeZI: "首位数投",
  l_gameTypeZJ: "首位红投",

  //Order Management
  l_status_text_A: "未开奖",
  l_status_text_W: "Won",
  l_status_text_S: "Lost",
  l_status_text_C: "个人撤单",
  l_status_text_M: "出号撤单",
  l_status_text_X: "追中撤单",
  l_status_text_O: "空开撤单",
  l_status_text_Y: "无效订单",
  l_status_text_Z: "无效订单",
  l_unit1: "1.0",
  l_unit2: "0.1",
  l_unit3: "0.01",
  l_unit4: "0.001",

  //Slot Games
  slotGames_0: "SLOT",
  slotGames_1: "ARCADE",
  slotGames_2: "SCRATCH",
  slotGames_3: "TABLE",

  //Jackpot Status
  jackpotStatus_A: "Approved",
  jackpotStatus_N: "New",
  jackpotStatus_C: "Completed",
  jackpotStatus_E: "Error",

  //Lottert Games
  l_lotteryChNameHELSSC: "Hi彩时时彩",
  l_lotteryChNameHELSSC1: "Hi彩分分彩",
  l_lotteryChNameHELSSC2: "Hi彩1.5分彩",
  l_lotteryChNameHLJS3D: "Hi彩极速3D",
  l_lotteryChNameHEL11X5: "Hi彩11选5",
  l_lotteryChNameHL11X5FFC: "Hi彩极速11选5",
  l_lotteryChNameHLPK10: "Hi极速PK10",
  l_lotteryChNameHIJSK3M1: "Hi极速快3",
  l_lotteryChNameHIK3M10: "10分快3",
  l_lotteryChNameHISSCM10: "10分时时彩",
  l_lotteryChNameHI11X5M10: "10分11选5",
  l_lotteryChNameHIPK10M5: "HiPK10",
  l_lotteryChNameROCKET: "小游戏-哪吒",
  l_lotteryChNameBLINDBOX: "小游戏-盲盒",
  l_lotteryChNameMILLIONAIRE: "小游戏-百万富翁",

  //Main Dashboard
  todays: "Today's",
  dashb_deposit: "Deposit",
  dashb_withdrawal: "Withdrawal",
  dashb_totalTurnover: "Turnover",
  dashb_netProfit: "Net Profit",
  dashb_balance: "Virtual Balance",
  dashb_newAccounts: "No. Of Newly Reg Users",
  dashb_depositAccounts: "No. Of Deposit Users",
  dashb_activeAccounts: "No. Of Active Players",
  dashb_newDepositAccounts: "No. Of First Time Deposits",
  dashb_ggr: "GGR",

  //Generic terms
  loginTitle: "SX2 BO LOGIN",
  welcome: "Welcome",
  yes: "Yes",
  no: "No",
  Y: "Yes",
  N: "No",
  submit: "Submit",
  close: "Close",
  textCopied: "Text Copied!",
  addUserRole: "Add User Role",
  editUserRole: "Edit User Role",
  parentRole: "Parent Role",
  googleAuthMasterSwitch: "Google Auth Master Switch",
  exp: "EXP",
  serialNum: "S/N",
  adjReportOnly: "Adjustment Report Only",
  loginName: "Login Name",
  firstName: "First Name",
  lastName: "Last Name",
  status: "Status",
  role: "Role",
  email: "Email",
  boundGoogleAuth: "Bound Google Auth (Y/N)",
  googleLogin: "Google Login",
  dateCreated: "Date Created",
  lastUpdated: "Last Updated",
  lastLoginTime: "Last Login Time",
  action: "Action",
  password: "Password",
  isOps: "Is Ops",
  roles: "Roles",
  allRoles: "All Roles",
  selectRole: "Select Role",
  selectRoles: "Select Roles",
  editUser: "Edit User",
  deleteUser: "Delete User",
  resetPassword: "Reset Password",
  newPassword: "New Password",
  refresh: "Refresh",
  addRole: "Add Role",
  roleChangeHistTable: "Role Change Hist Table",
  name: "Name",
  desc: "Description",
  create: "Create",
  limit: "Limit",
  updatedBy: "Updated By",
  updatedAt: "Updated At",
  remarks: "Remarks",
  loginId: "Login ID",
  memLvl: "Mem Lvl",
  memGrp: "Mem Grp",
  balance: "Balance",
  acctName: "Acct Name",
  acctNo: "Acct No.",
  bankName: "Bank Name",
  bankBranch: "Bank Branch",
  bankLoc: "Bank Loc",
  province: "Province",
  city: "City",
  agent: "Agent",
  labels: "Labels",
  regDate: "Reg Date",
  export: "Export",
  exportCsv: "Export",
  report: "Report",
  curLevel: "Cur Level",
  curExp: "Cur Exp",
  targetLevel: "Target Level",
  updateMemLevel: "Update Mem Level",
  updateMemGrp: "Update Mem Grp",
  memGrpAssign: "Mem Grp Assignment",
  verified: "Verified",
  unverified: "Unverified",
  newExp: "New Exp",
  acctBal: "Acct Bal",
  count: "Count",
  approve: "Approve",
  reject: "Reject",
  approved: "Approved",
  rejected: "Rejected",
  outstanding: "Outstanding",
  product: "Product",
  totalWagerCount: "Total Wager Count",
  stake: "Stake",
  ggr: "GGR",
  acctTransaction: "Account Transaction",
  betHistory: "Bet History",
  permAssign: "Permission Assignment",
  wfAssign: "Workflow Assignment",
  operator: "Operator",
  member: "Member",
  outboundCallPerm: "Outbound Call Permission",
  memListGrpPerm: "Member Listing Group Permission",
  permission: "Permission",
  access: "Access",
  checkAll: "Check All",
  roleId: "Role ID",
  roleName: "Role Name",
  roleChangeHist: "Role Change Hist",
  updateMemLvl: "Update Mem Lvl",
  genInfo: "General Info",
  acctStatement: "Acct Stmt",
  acctType: "Acct Type",
  total: "Total",
  fromAcct: "From Acct",
  toAcct: "To Acct",
  internalTransfer: "Internal Transfer",
  internalMsg: "Internal Msg",
  statusHist: "Status Hist",
  grpHist: "Grp Hist",
  securityInfo: "Security Info",
  depositChannel: "Deposit Channel",
  turnover: "T.O",
  sendMsg: "Send Msg",
  msgHist: "Msg Hist",
  prodWallet: "Prod Wallet",
  prodBal: "Prod Bal",
  notes: "Notes",
  updateMemStatus: "Update Mem Status",
  suspendedDays: "Suspended Days",
  memExpAdj: "Mem EXP Adjustment",
  loginTime: "Login Time",
  loginIp: "Login IP",
  clientType: "Client Type",
  transactions: "Transactions",
  transactionActivity: "Trans Activity",
  transactionTime: "Trans Time",
  transactionType: "Trans Type",
  transactionDate: "Trans Date",
  transactionId: "Trans ID",
  loginRecordsPast30Days: "Login records (Past 30 days)",
  agentRebate: "Agent Rebate",
  credit: "Credit",
  debit: "Debit",
  prevBalance: "Prev Bal",
  curBalance: "Cur Bal",
  amount: "Amt",
  submittedBy: "Submitted By",
  submittedAt: "Submitted At",
  approvedBy: "Approved By",
  approvedAt: "Approved At",
  oldVal: "Old Val",
  newVal: "New Val",
  curVal: "Cur Val",
  createdAt: "Created At",
  createdBy: "Created By",
  addComment: "Add Comment",
  comments: "Comments",
  lastUser: "Last User",
  currency: "Currency",
  isDefault: "Is Default",
  urlAddress: "URL/Address",
  withBankAccInfo: "Withdrawal Bank Account Info",
  virtCurInfo: "Virtual Currency Info",
  enableInterbank: "Enable Interbank",
  enableSamebank: "Enable Same bank",
  bankCode: "Bank Code",
  acctCode: "Acct Code",
  url: "URL",
  effectiveTO: "Effective T.O.",
  betTime: "Bet Time",
  betAmount: "Bet Amt",
  settledAmount: "Settled Amt",
  winAmount: "Win Amt",
  msgId: "Msg ID",
  msgType: "Msg Type",
  from: "From",
  to: "To",
  subject: "Subject",
  sentTime: "Sent Time",
  type: "Type",
  unread: "Unread",
  read: "Read",
  settings: "Settings",
  locked: "Locked",
  unlocked: "Unlocked",
  history: "History",
  riskLevel: "Risk Level",
  currentRiskLvl: "Current Risk Lvl",
  targetRiskLvl: "Target Risk Lvl",
  memRiskLvl: "Member Risk Lvl",
  riskLvlHist: "Risk Lvl Hist",
  memLabels: "Mem Labels",
  addNewLabels: "Add New Labels",
  id: "ID",
  group: "Grp",
  defaultGroup: "Default Grp",
  reimbursement: "Reimbursement (%)",
  minReimbursement: "Min Reimbursement",
  maxReimbursement: "Max Reimbursement",
  rounding: "Rounding",
  reimbursementMinDeposit: "Reimbursement Min Deposit",
  addNewGroup: "Add New Grp",
  groupName: "Grp Name",
  addMemberGroup: "Add Mem Grp",
  editMemberGroup: "Edit Mem Grp",
  connections: "Conns",
  connectedDate: "Connected Date",
  masterAgent: "Master Agent",
  directAgent: "Direct Agent",
  bankCardNumber: "Bank Card No.",
  searchedMember: "Searched Mem",
  blockedTime: "Blocked Time",
  blockedBy: "Blocked By",
  adjustmentType: "Adjustment Type",
  requestor: "Requestor",
  approver: "Approver",
  enable: "Enable",
  disable: "Disable",
  verifiedEmail: "Verified Email",
  verifiedMobile: "Verified Mobile",
  verifiedWithdrawal: "Verified WD",
  addNewRule: "Add New Rule",
  add: "Add",
  update: "Update",
  addRuleSetting: "Add Rule Setting",
  updateRuleSetting: "Update Rule Setting",
  taskId: "Task ID",
  wfName: "WF Name",
  priorityLvl: "Priority Lvl",
  requestedAdminId: "Req Adm Id",
  requestedBy: "Req By",
  requestedAt: "Req At",
  workflow: "Workflow",
  operation: "Operation",
  location: "Location",
  updatedTime: "Updated Time",
  updatedDate: "Updated Date",
  requestTime: "Request Time",
  bank: "Bank",
  fromAccount: "From Acct",
  toAccount: "To Acct",
  customer: "Customer",
  param1: "Param 1",
  param2: "Param 2",
  all: "All",
  claim: "Claim",
  claimAndEnter: "Claim & Enter",
  workflowId: "WF ID",
  workflowData: "WF Data",
  target: "Target",
  value: "Value",
  entry: "Entry",
  entries: "Entries",
  minBet: "Min Bet",
  drawTime: "Draw Time",
  announcementTime: "Annc Time",
  jackpotName: "Jackpot Name",
  jackpotValue: "Jackpot Value",
  nextAnnouncementSchedule: "Next Annc Schedule",
  autoApprovalTicket: "Auto Appr Ticket",
  autoApprovalSchedule: "Auto Appr Schedule",
  minBetAmt: "Min Bet Amt",
  injectVal: "Inject Val",
  inject: "Inject",
  addLabel: "Add Label",
  labelId: "Label ID",
  labelName: "Label Name",
  enabled: "Enabled",
  disabled: "Disabled",
  addNewLabel: "Add New Label",
  editLabel: "Edit Label",
  targetId: "Target ID",
  auditId: "Audit ID",
  games: "Games",
  deposit: "Deposit",
  withdrawal: "Withdrawal",
  payout: "Payout",
  idealAmount: "Ideal Amt",
  rebate: "Rebate",
  uplineAgents: "Upline Agts",
  mainAccountBalance: "Main Acct Bal",
  dateRegistered: "Date Reg",
  backOfficeUser: "BO User",
  targetType: "Target Type",
  targetValue: "Target Val",
  menuCodes: "Menu Codes",
  accessDate: "Access Date",
  resultCode: "Result Code",
  userId: "User ID",
  username: "Username",
  opTypes: "Op Types",
  newField: "New Field",
  inbox: "Inbox",
  sent: "Sent",
  basicSearch: "Basic Search",
  advancedSearch: "Adv Search",
  exact: "Exact",
  recipient: "Recipient",
  recipientCount: "Recipient Count",
  progress: "Progress",
  msg: "Msg",
  msgStatus: "Msg Status",
  customerService: "Customer Service",
  details: "Details",
  msgDetails: "Msg Details",
  attachment: "Attachment",
  reply: "Reply",
  shortcuts: "Shortcuts",
  confirmDetails: "Confirm Details",
  selectTemplate: "Select Template",
  uploadAttachment: "Upload Attachment",
  hideReply: "Hide Reply",
  showReply: "Show Reply",
  hideMessage: "Hide Message",
  showMessage: "Show Message",
  googleOtp: "Google OTP",
  oneTimePassword: "One-time Password",
  viewDetails: "View Details",
  orderMgm: "Order Mgm",
  withdrawRequestDetails: "Withdraw Request Details",
  turnoverSinceLastWithdraw: "T.O Since Last Withdraw",
  beginningBalance: "Beginning Balance",
  totalAmount: "Total Amount",
  nettAmount: "Nett Amount",
  send: "Send",
  includeAgent: "Include Agent",
  direct: "Direct",
  sms: "SMS",
  availableShortcuts: "Available Shortcuts",
  addNewTemplate: "Add New Template",
  templateName: "Template Name",
  templateDescription: "Template Desc",
  uuid: "UUID",
  lastRetrievedAt: "Last Retrieved At",
  recipients: "Recipients",
  repair: "Repair",
  download: "Download",
  publisher: "Publisher",
  publisherTime: "Publisher Time",
  validFrom: "Valid From",
  validTill: "Valid Till",
  audience: "Audience",
  urgency: "Urgency",
  addAnnouncement: "Add Announcement",
  publishTime: "Publish Time",
  brand: "Brand",
  site: "Site",
  platform: "Platform",
  content: "Content",
  fromTime: "From Time",
  tillTime: "Till Time",
  publishAll: "Publish All",
  maintenanceDateTime: "Maint Date Time",
  saveAsDraft: "Save As Draft",
  publish: "Publish",
  auto: "Auto",
  autoApprovalEnabled: "Auto Approval Enabled",
  autoApprovePromotionClaim: "Auto Approve Promotion Claim",
  accountHolderName: "Account Holder Name",
  bindBankCard: "Bind Bank Card",
  bankAccountNumber: "Bank Acct No.",
  maxApprovedClaim: "Max Approved Claim",
  none: "None",
  current: "Current",
  vipLevel: "VIP Level",
  lotteryType: "Lottery Type",
  masterSwitch: "Master Switch",
  autoApprovalSettings: "Auto Approval Settings",
  autoApprovalMasterSwitch: "Auto Approval Master Switch",
  drawPeriod: "Draw Period",
  winningNumber: "Winning No.",
  timeEncoded: "Time Encoded",
  timeVerified: "Time Verified",
  timeTakenSec: "Taken (sec.)",
  time1Sec: "Time 1 (sec.)",
  time2Sec: "Time 2 (sec.)",
  time3Sec: "Time 3 (sec.)",
  time4Sec: "Time 4 (sec.)",
  encodedBy: "Encoded By",
  verifiedBy: "Verified By",
  period: "Period",
  lottery: "Lottery",
  timeTotal: "Time Total",
  disburseStart: "Disburse Start",
  hideWithoutRemarks: "Hide those without remarks",
  frequencySec: "Frequency (Sec)",
  numberDrawPeriods: "# of Draw Periods",
  numberPastDraws: "No. Past Draws",
  lockInSec: "Lock In (Sec.)",
  nextDrawSec: "Next Draw (Sec.)",
  startTime: "Start Time",
  drawDate: "Draw Date",
  dailyIndex: "Daily Index",
  daySelection: "Day Selection",
  requestUpdate: "Request Update",
  lockInPeriod: "Lock In Period",
  sale: "Sale",
  maintenance: "Maintenance",
  display: "Display",
  hot: "Hot",
  new: "New",
  old: "Old",
  blackoutSetting: "Blackout Setting",
  blackoutDate: "Blackout Date",
  advancedSaleDay: "Adv Sale Day",
  gameType: "Game Type",
  batchApply: "Batch Apply",
  apply: "Apply",
  selectAll: "Select All",
  dateRange: "Date Range",
  lotteryGroup: "Lott Grp",
  prize: "Prize",
  idealPrize: "Ideal Prize",
  "w/l": "Win/Loss",
  activeUsers: "Active Users",
  orderNumber: "Order No.",
  minimumBetAmount: "Min Bet Amt",
  max: "Max",
  settlementTime: "Settlement Time",
  settlementAmount: "Settlement Amt",
  betMultiplier: "Bet Multipler",
  betMode: "Bet Mode",
  ip: "IP",
  unitCount: "Unit Count",
  cancelOrder: "Cancel Order",
  betDetails: "Bet Details",
  orderDetails: "Order Details",
  productName: "Prod Name",
  margin: "Margin",
  productCategory: "Prod Cat",
  memberGroup: "Mem Grp",
  winningMember: "Winning Mem",
  productGGR: "Product GGR",
  transferIn: "Transfer In",
  pastHour: "Past Hour",
  date: "Date",
  time: "Time",
  topWinner: "Top Winner",
  winnerGGR: "Winner GGR",
  showGameTypeDetails: "Show Game Type Details",
  hideGameTypeDetails: "Hide Game Type Details",
  minute: "Min",
  minutes: "Mins",
  hour: "Hour",
  withdrawAmount: "WD Amt",
  withdrawCount: "WD Count",
  meanTime: "Mean Time",
  processTimeTaken: "Process Time Taken",
  autoVsManual: "Auto Vs Manual",
  market: "Market",
  withdrawRequestGraph: "WD Request Graph",
  top10LongestWithdrawal: "Top 10 longest WD",
  processor: "Processor",
  claimToApproved: "Claim To Appr",
  manual: "Manual",
  reqToClaim: "Req To Claim",
  approvedCount: "Approved Count",
  rejectedCount: "Rejected Count",
  maxAutoApprovalAmount: "Max Auto Approval Amt",
  memberStatusAllowed: "Mem Status Allowed",
  winningAmount: "Winning Amt",
  timeSpentSec: "Time Spent (Sec)",
  gameId: "Game ID",
  inspectionStatus: "Inspection Status",
  loginCode: "Login Code",
  seriesNumber: "Series No.",
  excessTime: "Excess Time",
  lockInTime: "Lock In Time",
  checkedTime: "Checked Time",
  result: "Result",
  filterName: "Filter Name",
  averageDailyDeposit: "Avg Daily Dep",
  nonLottery: "Non-Lottery",
  nameChanged: "Name Changed",
  wdBankCardBound: "WD Bank Card Bound",
  activeBankCard: "Active Bank Card",
  lastClearedBy: "Last Cleared By",
  lastClearedAt: "Last Cleared At",
  clearedUntil: "Cleared Until",
  averageDailyWd: "Avg Daily WD",
  depositDiffName: "Dep Diff Name",
  downlineTransferAmount: "DL Trans Amt",
  turnoverDepositRatio: "T.O vs. Dep Ratio",
  clearMember: "Clear Mem",
  whitelistDay: "Whitelist Day",
  max7Days: "Maximum of 7 days.",
  memberGroupAssignment: "Mem Grp Assignment",
  addFilter: "Add Filter",
  testRun: "Test Run",
  days: "Days",
  lotteryTurnoverDepositPercentage: "Lott T.O vs. Dep Percentage",
  nonLotteryTurnoverDepositPercentage: "Non Lott T.O vs. Dep Percentage",
  times: "Times",
  depositDifferentName: "Dep using diff name",
  bindWdBankCard: "Bind WD Bank Card",
  totalActiveBankCard: "Total Active Bank Card",
  expandAll: "Expand All",
  collapseAll: "Collapse All",
  reportDate: "Rpt Date",
  newDeposit: "New Deposit",
  depositUsers: "Deposit Users",
  promotionCost: "Promo Cost",
  ngr: "NGR",
  netWin: "Net Win",
  netWinAfterCommission: "Net Win Aft Comm",
  liveUsers: "Live Users",
  totalTurnover: "Total Turnover",
  totalPayout: "Total Payout",
  customerRebate: "Customer Rebate",
  agentCommission: "Agent PS",
  sTypeAgentRebate: "S-Type Agent Rebate",
  sTypeAgentPS: "S-Type Agent PS",
  cpCleared: "CP Cleared P&L",
  vendorCleared: "Vendor Cleared P&L",
  customerAdjustment: "Customer Adj",
  compensation: "Compensation",
  bankCharge: "Bank Charge",
  afterRebate: "Aft Rebate",
  nameEn: "Name (EN)",
  nameCn: "Name (CN)",
  html5GameCode: "HTML5 Game Code",
  flashGameCode: "Flash Game Code",
  progressive: "Progressive",
  priority: "Priority",
  category: "Category",
  lines: "Lines",
  RTP: "RTP",
  onOff: "On/Off",
  vendor: "Vendor",
  addNewSlotGame: "Add New Slot Game",
  slot: "Slot",
  arcade: "Arcade",
  scratch: "Scratch",
  table: "Table",
  productTransferUpdateStatus: "Prod Trans Update Status",
  dateTime: "Date Time",
  inHouse: "In-House",
  drawId: "Draw ID",
  jackpotApproval: "Jackpot Approval",
  memberWinnerList: "Member Winner List",
  initialBalance: "Initial Bal",
  earnedBalance: "Earned Bal",
  injectedBalance: "Injected Bal",
  broughtForward: "Brought Fwd",
  approvedRejectedBy: "Appr/Rej By",
  approvedTime: "Appr Time",
  approvedStatus: "Appr Status",
  expiryTime: "Expiry Time",
  bankAccountName: "Bank Acct Name",
  announcedTime: "Annc Time",
  broughtForwardBalance: "Brought Fwd Bal",
  sendInbox: "Send Inbox",
  sendAllInbox: "Select all inbox",
  approvalStatus: "Approval Status",
  scratchTime: "Scratch Time",
  selectAllInbox: "Select All Inbox",
  totalDailyAmount: "Total Daily Amt",
  dailyApprovalMember: "Daily Approval Mem",
  totalApprovalAmount: "Total Approval Amt",
  dailyClaimedMember: "Daily Claimed Mem",
  totalClaimedAmount: "Total Claimed Amt",
  average: "Avg",
  miniGames: "Mini Games",
  sequenceNumber: "Seq No.",
  saleTill: "Sale Till",
  onSale: "On Sale",
  miniGame: "Mini Game",
  betDetail: "Bet Detail",
  drawResult: "Draw Result",
  orderStatus: "Order Status",
  buyAmount: "Buy Amt",
  netAmount: "Net Amt",
  settlementDateTime: "Settlement Date/Time",
  betAmountMin: "Bet Amt Min",
  betAmountMax: "Bet Amt Max",
  allGames: "All Games",
  summary: "Summary",
  miniGameType: "Mini Game Type",
  googleAuthentication: "Google Auth",
  edit: "Edit",
  active: "Active",
  inactive: "Inactive",

  //Success Custom Message for File Upload
  fileUploadedSuccessfully: "File Uploaded Successfully",

  //Order Mgm - Channel Type Dropdown Mapping
  channelType_QKDEP: "极速充值",
  channelType_QKALI: "极速支付宝",
  channelType_QKWECHAT: "极速微信",
  channelType_UNKNOWN: "Select Channel Type",

  //Order Mgm - Verify Modal - History
  requestAmt: "Request Amt",
  actualDepAmt: "Actual Successful Amt",
  verifiedAt: "Verified At",
  viewAttach: "View Attachment",

  //Merchant Mgm - Merchant Listing
  merchantType: "Merchant Type",
  editMerchant: "Edit Merchant",
  ledgerBalance: "Ledger Balance",
  creditBalance: "Credit Balance",
  creditMultiplier: "Credit Multiplier",
  splitWd: "Split Wd",
  depMin: "Dep Min",
  depMax: "Dep Max",
  depMaxCallback: "Dep Max Callback",
  wdMaxCallback: "Wd Max Callback",
  wdRequestTimeout: "Wd Request Timeout",
  depTransferTimeout: "Dep Transfer Timeout",
  wdCheckTimeout: "Wd Check Timeout",
  wdPenaltyTimeout: "Wd Penalty Timeout",
  minAmtToSplit: "Min Amt to Split",
  maxAmtToSplit: "Max Amt to Split",
  withdrawSplitMaxMiss: "Withdraw Split Max Miss",
  PreExtensionExpiryDateTimeout: "Pre Extension Expiry Date Timeout",
  isLastRelease: "Is Last Release",
  wdCallbackHost: "Wd Callback Host",
  depCallbackHost: "Dep Callback Host",
  depTransferVideoHost: "Dep Transfer Video Host",
  anyWdAmt: "Any Wd Amt",
  dataRetentionDays: "Data Retention Days",
  depTransferVideoHostToMM: "Dep Transfer Video Host to MM",
  wdSuspendedAmt: "Wd Suspended Amt",
  wdSuspensionTimeout: "Wd Suspension Timeout",

  //Batch Job - Batch Job Listing
  jobName: "Job Name",
  jobGroupName: "Job Group Name",
  jobDescription: "Job Description",
  cronExpression: "Cron Expression",
  batchSize: "Batch Size",
  resumeAll: "Resume All",
  pauseAll: "Pause All",
  addBatchJob: "Add Batch Job",
  rescheduleBatchJob: "Reschedule Batch Job",
  triggerName: "Trigger Name",
  triggerGroupName: "Trigger Group Name",
  job: "Job",
  selectJob: "Select Job",
  className: "Job Class Name",
  confirmResumeAll: "Are you sure you want to resume all jobs?",
  confirmPauseAll: "Are you sure you want to pause all jobs?",
  confirmDeleteBatchJob: "Yes, delete job",
  confirmResumeAllYes: "Yes, resume all jobs",
  confirmPauseAllYes: "Yes, pause all jobs",
  confirmPauseJob: "Yes, pause job",
  confirmResumeJob: "Yes, resume job",

  //Deposit Mgm - Deposit Listing
  merchant: "Merchant",
  orderNo: "Order #",
  memId: "Mem. ID",
  depAmt: "Amount (¥)",
  merchantPriority: "Merchant Priority",
  originalAmt: "Original Amt (¥)",
  recommendedAmts: "Recommended Amts",

  //Black Listing
  hashedMemId: "Hashed Mem Id",
  realName: "Real Name",
  requestedOps: "Requested Ops",
  isBlackListed: "Black List",
  blacklistOp: "Operation",

  //Fraud
  depositId: "Deposit Id",
  partyOrderNo: "Party Order #",

  //System Config
  maxAmt: "Max Amt",
  timeout: "Timeout",
  retryCutoff: "Retry Cutoff",
  modifyConfig: "Modify Config",
  updateConfig: "Update Config",
  globalSetting: "Default Global Setting",
  withdrawTimeoutRange: "Withdraw Timeout Range",
  wdMaxSplit: "Wd Max Split",
  wdMinPercentLimit: "Wd Min Percent Limit",
  wdMinAmt: "Wd Min Amt",
  depMinAmt: "Dep Min Amt",
  matchingAndSplittingSetting: "Default Matching & Splitting Setting",
  depModule: "Dep Module",
  minAmt: "Min Amt",
  recommendRangeAmt: "Recommend Range Amt",
  recommendMaxCount: "Recommend Max Count",
  recommendMultiplier: "Recommend Multiplier",
  wdTimeout: "Wd Timeout (Sec)",
  splitCount: "Split Count",
  splitCountRange: "Split Count Range",
  recommendRemainingTimeout: "Recommend Remaining Timeout (Sec)",
  recommendCount: "Recommend Count",
};

export default enLabels;
