import { makeAutoObservable } from "mobx";
import request from "service/request";
import api from "service/api";
import { hash } from "utils";
import MenuData from "config/hardcodedMenu";
import {
  persistence,
  StorageAdapter,
  isSynchronized,
} from "mobx-persist-store";
import { readStore, writeStore } from "stores/persist";
// import tzStore from "./timezone";

class UserStore {
  constructor() {
    makeAutoObservable(this);
  }

  forceLogout = false;
  checkAuth = false;
  adminID = null;
  authToken = null;
  platformID = null;
  roles = null;
  username = null;
  password = null;
  menu = [];
  menuSettings = {
    selectedKeys: [],
    openKeys: [],
    collapsed: false,
  };
  menuTabs = [];
  toggledTab = "";
  iframeLoaded = false;
  promptPwd = false;
  menuCode = [];
  //isAdmin = false;

  //Store order mgm obj and name
  orderKeyName = { key: "/orderMgm", name: "Order Management" };
  orderName = "Order Management";

  //Google OTP
  showGoogleOtp = false;
  userStatus = {};
  googleCode = null;

  //Check if a particular tab is active
  checkTab(name, key) {
    return this.menuTabs.some((tab) => tab.name === name || tab.key === key);
  }

  tabAlert(name, toAdd) {
    let index = this.menuTabs.findIndex((mTab) => mTab.name === name);
    if (this.menuTabs[index]) {
      this.menuTabs[index].alert = toAdd;
    }
  }

  checkSession() {
    return new Promise((resolve, reject) => {
      request(api.checkSession, {
        headers: {
          "login-admId": this.adminID,
        },
        form: {
          admId: this.adminID,
          authToken: this.authToken,
        },
      })
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((err) => reject(err));
    });
  }

  // Checks a couple of things related to the user
  // #1 - Checks if the user account is currently being logged into
  // multipleLogin
  // #2 - Checks if the user account requires Google OTP to login (Master switch)
  // googleOTPMasterSwitch
  // #3 - Checks if the user account requires Google OTP to login (Individual Switch)
  // googleOTPSwitch, qrCode
  // #4 - Checks if the user account has been bound to the Google Auth app
  // googleOTPBindStatus

  checkUserStatus(username) {
    return new Promise(async (resolve, reject) => {
      try {
        const params = {
          form: { username: username },
        };
        const res = await request(api.checkUser2FA, params);
        this.userStatus = res.data.data;
        this.authToken = res.data.data.authToken;

        resolve();
      } catch (err) {
        reject(err);
      }
    });
  }

  //Real login
  //Sends username, OTP, auth token
  login() {
    return new Promise(async (resolve, reject) => {
      try {
        //Hash password with sha1
        let hashedPw = hash("sha1", this.password);
        //Concatenate auth token with resulting password and hash it with sha256
        hashedPw = hash("sha256", this.authToken + hashedPw);

        const res = await request(api.userLogin, {
          form: {
            username: this.username,
            authToken: this.authToken,
            password: hashedPw,
            googleCode: this.googleCode,
          },
          headers: {
            "audit-kw": this.username,
          },
        });
        const { data } = res;
        if (data.code === 0) {
          this.adminID = data.data.permTree.admId;
          //this.platformID = data.data.permTree.platformIds[0];
          this.roles = data.data.permTree.roles;
          // const getAdmin =  data.data.permTree?.roles?.filter((role) => role.id === 1);
          // this.isAdmin = getAdmin.length > 0 ? true : false;
          this.menuCode = data.data.menuCode;
          localStorage.setItem("menucode", data.data.menuCode);
          //this.username = username;
          /* const permissions = this.extractPermissions();
          this.extractMenus(permissions); */
          this.forceLogout = false;
          this.promptPwd = data.data.forceChgPwd === "Y";

          resolve(data);
        } else {
          //Previously, there was no specific error msg for incorrect otp, and no checks for the code 20048 (for incorrect otp) "code not 200" can now be removed with the custom error msg in place
          //customError("Invalid username or password!");
          reject(data);
          return;
        }
      } catch (err) {
        reject(err);
      }
    });
  }

  extractPermissions() {
    const mainMenu = ["home", "M_C"];
    const apis = ["Home", "Send Message"];

    this.roles.forEach((r) => {
      r?.permissions.forEach((p) => {
        //Check if inMenu is "Y" AND menuCode is not null
        if (p.inMenu && p.inMenu.toLowerCase() === "y" && p.menuCode) {
          //Add it to mainMenu array if it does not already exist
          if (!mainMenu.includes(p.menuCode)) {
            mainMenu.push(p.menuCode);
          }

          //Check for sub menus
          p.apis.forEach((a) => {
            if (a.uiType && a.uiType.toLowerCase() === "menu") {
              //Add it to Apis array if it does not already exist
              if (!apis.includes(a.title)) {
                apis.push(a.title);
              }
            }
          });
        }
      });
    });
    return { apis, mainMenu };
  }

  extractMenus({ apis, mainMenu }) {
    this.menu = [];
    MenuData.forEach((m) => {
      //Check if main menu is to be rendered
      if (m.menuCode && mainMenu.includes(m.menuCode)) {
        const children = [];
        m.children = m.children.forEach((c) => {
          if (c.title && apis.includes(c.title)) {
            children.push({ ...c });
          }
        });
        m.children = children;
        this.menu.push({ ...m });
      }
    });
  }

  logout() {
    //Clear all error messages
    //document.querySelectorAll('.ant-message-notice').forEach(item=>item.remove())
    try {
      request(api.userLogout, {
        form: {
          admId: this.adminID,
          //authToken: this.authToken,
        },
      });

      this.adminID = null;
      this.platformID = null;
      this.roles = null;
      this.menuCodeusername = null;
      this.menu = [];
      this.username = null;
      this.authToken = null;
      this.menuTabs = [];
    } catch (err) {
      console.log(err);
    }
  }

  setCollapsed(collapsed) {
    this.menuSettings = { ...this.menuSettings, collapsed };
  }

  setOpenKeys(openKeys) {
    this.menuSettings = { ...this.menuSettings, openKeys };
  }

  setSelectedKeys(selectedKeys) {
    this.menuSettings = { ...this.menuSettings, selectedKeys };
  }

  get isSynchronized() {
    return isSynchronized(this);
  }

  changePwd(formData) {
    return new Promise((resolve, reject) => {
      request(api.resetPassword, {
        params: {
          userToResetId: this.adminID,
          oldPassword: formData.oldPassword,
          password: formData.newPassword,
          confirmPassword: formData.confirm,
          userId: this.adminID,
        },
      })
        .then((res) => resolve(res))
        .catch((err) => reject(err));
      //[500] during [POST] to [http://mm-auth-cache/auth_bo/reset_password] [AuthCacheClient#boPostByBody(String,Map)]: [{\"code\":20001,\"data\":\"Incorrect existing password\"}]"
    });
  }
}
export default persistence({
  name: "UserStore",
  properties: [
    "menu",
    "adminID",
    "platformID",
    "username",
    "menuSettings",
    "authToken",
    "promptPwd",
  ],
  adapter: new StorageAdapter({ read: readStore, write: writeStore }),
  reactionOptions: { delay: 200 },
})(new UserStore());
