import { HashRouter as Router } from "react-router-dom";
import PageRoutes from "./pageRoutes";
import "./App.less";
import { ConfigProvider } from "antd";
import { observer } from "mobx-react-lite";
import i18n from "stores/i18n";

function App() {
  return (
    <ConfigProvider locale={i18n.antLang}>
      <Router>
        <PageRoutes />
      </Router>
    </ConfigProvider>
  );
}

export default observer(App);
