import { Button, message } from "antd";
import Icon from "components/icon";

export default function customError(error) {
  let msgLimit = 3;
  let msgs = document.querySelectorAll(".ant-message-notice");

  //Check if number of error messages shown currently has reached the limit
  if (msgs.length >= msgLimit) {
    //Reached the Limit, remove the oldest one before adding new one
    msgs[0].remove();
  }

  //Create new Error Message
  message.error({
    content: (
      <span>
        {error?.toString()}
        <Button className="closeErr" type="text">
          <Icon className="closeErr" type="CloseOutlined" />
        </Button>
      </span>
    ),
    duration: 0,
    onClick: (e) => {
      if (
        e.target.className.toString().includes("closeErr") ||
        e.target.tagName === "svg" ||
        e.target.tagName === "path"
      ) {
        e.currentTarget.remove();
      }
    },
  });
}
