import { lazy } from "react";
import { Switch, Route } from "react-router-dom";

//Generic Pages
const Page404 = lazy(() => import("pages/404.js"));
const OrderMgm = lazy(() => import("pages/orderMgm"));
const UserMgm = lazy(() => import("pages/userMgm"));
const RoleMgm = lazy(() => import("pages/roleMgm"));
const BatchJob = lazy(() => import("pages/batchJob"));
const CreditScore = lazy(() => import("pages/creditRating/creditScore"));
// const IgnoreCreditUsers = lazy(() =>
//   import("pages/creditRating/ignoreCreditUsers")
// );
const CreditConfig = lazy(() => import("pages/creditRating/creditConfig"));
const CreditLevel = lazy(() => import("pages/creditRating/creditLevel"));

const MerchantMgm = lazy(() => import("pages/merchantMgm"));
const DepositMgm = lazy(() => import("pages/depositMgm"));
const WithdrawalKpi = lazy(() => import("pages/reports/withdrawalKpi"));
const DepositKpi = lazy(() => import("pages/reports/depositKpi"));

const BlackList = lazy(() => import("pages/blackList/blackList/index.js"));
const BlackListHistory = lazy(() => import("pages/blackList/blackListHistory/index.js"));

const FraudList = lazy(() => import("pages/fraudList/index.js"));
const MatchingConfigList = lazy(() => import("pages/systemConfiguration/matchingConfiguration"));

const Routes = () => {
  return (
    <Switch>
      {/* Generic Pages */}
      <Route path="/orderMgm" component={OrderMgm} />
      <Route path="/userMgm/userListing" component={UserMgm} />
      <Route path="/userMgm/roleListing" component={RoleMgm} />
      <Route path="/batchJob" component={BatchJob} />
      <Route path="/merchantMgm" component={MerchantMgm} />
      <Route path="/depositMgm/depositListing" component={DepositMgm} />
      <Route path="/creditRating/creditScore" component={CreditScore} />
      <Route path="/creditRating/creditConfig" component={CreditConfig} />
      <Route path="/creditRating/creditLevel" component={CreditLevel} />
      {/* <Route path="/ignoreCreditUsers" component={IgnoreCreditUsers} /> */}
      <Route path="/reports/withdrawalKpi" component={WithdrawalKpi} />
      <Route path="/reports/depositKpi" component={DepositKpi} />
      <Route path="/blackList/blackListListing" component={BlackList} />
      <Route path="/blackList/blackListHistory" component={BlackListHistory} />
      <Route path="/fraudList/fraudListing" component={FraudList} />
      <Route path="/systemConfiguration/matchingConfiguration" component={MatchingConfigList} />
      {/* User Navigates to the root page, we check if they are logged in before deciding where to send them */}
      <Route path="*" component={Page404} />
    </Switch>
  );
};
export default Routes;
