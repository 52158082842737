/* eslint-disable import/no-anonymous-default-export */
import { makeAutoObservable } from "mobx";
import request from "service/request";
import api from "service/api";
import { camelToTitle } from "utils";

class CommonStore {
  constructor() {
    makeAutoObservable(this);
  }

  refreshPg = false;
  orderListIsShown = false;
  showAlert = false;
  alertMsg = "";
  loading = false;
  updating = false;
  exporting = false;
  exportingUserAction = false;

  list = [];
  pagination = {
    totalCount: 0,
    page: 1,
    size: 25,
    sortAsc: true,
    sortingDto: null,
  };

  selMember = null;

  getList(apiName, data) {
    this.loading = true;
    return new Promise((resolve, reject) => {
      request(api[apiName], data)
        .then((res) => {
          this.list = res.data.data.items
            ? res.data.data.items.map((item, index) => ({
                ...item,
                key: index,
              }))
            : null;
          this.pagination.totalCount = res.data.data.totalCount;
          if (res.data.data.items) {
            this.pagination.sortingDto = Object.fromEntries(
              Object.entries(res.data.data.items[0]).map((item) => [
                item[0],
                false,
              ])
            );
          }

          resolve();
        })
        .catch((e) => {
          console.log(e);
          reject();
        })
        .finally(() => (this.loading = false));
    });
  }

  //OLD VERSION
  handleTableChange(pagi, filter, sort) {
    //Extract data we need and set
    this.pagination.page =
      this.pagination.page === pagi.current ? 1 : pagi.current; //Check if page number is the same, if it is means the user is either sorting or changing page SIZE, either way we reset the page number to 1
    this.pagination.size = pagi.pageSize;
    this.pagination.sortAsc = sort.order === "descend" ? false : true;
    this.pagination.sortingDto = {
      //Reset all fields to false
      ...Object.fromEntries(
        Object.entries(this.pagination.sortingDto).map((item) => [
          item[0],
          false,
        ])
      ),
      //Set sort field to true
      [sort.field]: true,
    };
  }

  //Converts a string into a CSV file
  createCsv(csvStr, name) {
    //Encode our CSV file
    let csvFinal = encodeURI(csvStr);
    //console.log("Our final encoded CSV string:", csvFinal);

    //Create hidden download link
    let hiddenLink = document.createElement("a");
    hiddenLink.href = `data:text/csv;charset=utf-8-sig,\uFEFF${csvFinal}`;

    //Give the file a name
    if (name) {
      hiddenLink.download = `${name}.csv`;
    } else {
      //Give the name as today's date and time
      let d = new Date();
      let dateStr = `${d.getDate()}-${d.getMonth() + 1}-${d.getFullYear()}`;
      let timeStr = `${d.getHours()}-${d.getMinutes()}-${d.getSeconds()}`;
      hiddenLink.download = `${dateStr}_${timeStr}.csv`;
    }

    //Simulate the click on the hidden element
    hiddenLink.click();
  }

  // 1st Parameter - data  - is sent as a json object instead

  // 2nd Parameter - removeItems - is an array of strings
  // strings are the header names of columns we do NOT want to export
  // eg. ["memId", "memName"]
  // DO NOTE that the header names refer to the key of the data sent from the backend (usually in camelCase)
  // *** PASS in an EMPTY array if nothing is to be removed AND you want to use a 3rd parameter

  // 3rd Parameter - mapping - is an object
  // {
  //   headers:{keyName:function},
  //   body:{keyName:function},
  //   summary:{keyName:function}
  // }
  // keyName - the header name of the column we want to rename
  // function - an anonymous function which has 1 parameter - the value of the cell (string)
  // (cell:string) => cell:any

  jsonExportCsv(data, removeItems = [], mapping) {
    //Convert the object into a string
    let csv = "";
    let rmIdx = [];
    if (data.headers) {
      //Don't modify 'data.headers' as we need to use it as a key for body/summary
      let newHeaders = data.headers.reduce((all, header) => {
        //Check whether the item should be removed
        //Item should NOT be removed, continue
        if (removeItems.indexOf(header) === -1) {
          //check if there are any mappings for this item
          //There is a mapping, use the mapping function
          if (mapping?.headers?.[header] !== undefined) {
            header = mapping?.headers[header](header);
          }
          //There is no mapping, just convert it to title case
          else {
            header = camelToTitle(header);
          }
          //Replace all "," as it is a special character for CSV files
          header = header.replaceAll(",", "-");
          return [...all, header];
        }
        //Item to be removed, no action for header, however we need to save the index for the body and summary
        else {
          rmIdx.push(data.headers.indexOf(header));
        }
        return all;
      }, []);
      csv += newHeaders?.join(",") + "\n";
    }

    if (data.body) {
      //Loop through all rows
      data.body.forEach((row) => {
        row = row.reduce((all, cell, idx) => {
          //Check whether the item should be removed
          //Item should NOT be removed, continue
          if (rmIdx.indexOf(idx) === -1) {
            //check if there are any mappings for this item
            //There is a mapping, use the mapping function
            if (mapping?.body?.[data.headers[idx]] !== undefined) {
              cell = mapping?.body[data.headers[idx]](cell);
            }

            //Replace all "," as it is a special character for CSV files
            cell = cell.toString().replaceAll(",", "-");
            return [...all, cell];
          }
          return all;
        }, []);
        csv += row?.join(",") + "\n";
      });
    }

    if (data.summary) {
      data.summary = data.summary.reduce((all, sumItem, idx) => {
        //Check whether the item should be removed
        //Item should NOT be removed, continue
        if (rmIdx.indexOf(idx) === -1) {
          //check if there are any mappings for this item
          //There is a mapping, use the mapping function
          if (mapping?.summary?.[data.headers[idx]] !== undefined) {
            sumItem = mapping?.summary[data.headers[idx]](sumItem);
          }
          //There is no mapping, just convert it to title case
          else {
            sumItem = camelToTitle(sumItem);
          }
          //Replace all "," as it is a special character for CSV files
          sumItem = sumItem.toString().replaceAll(",", "-");
          return [...all, sumItem];
        }
        return all;
      }, []);
      csv += data.summary?.join(",") + "\n";
    }
    this.createCsv(csv, data.name);
  }

  //Download ANY file
  //Remember to add responseType: "blob" to Axios when making request
  download(data, fileName) {
    const url = URL.createObjectURL(new Blob([data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  //Thumbnail function
  dlList = [];
  dlListThumb = [];
  showFullImg = false;
  fullImgSrc = null;
  selImg = {};
  listLoading = false;
  listFiles(attachId) {
    this.listLoading = true;

    return new Promise((resolveAll) => {
      request(api.listFiles, {
        params: {
          attachmentsId: attachId,
        },
      }).then((res) => {
        this.dlList = res.data.data;
        this.dlListThumb = res.data.data;

        //DOWNLOAD ALL IMAGE FILES AS THUMBNAIL
        //PART 1
        this.dlListThumb = [];
        let imgWhitelist = ["jpg", "png", "gif", "svg"];
        res.data.data?.forEach((file) => {
          let fileParts = file.split(".");
          let fileExt = fileParts[fileParts.length - 1];
          if (imgWhitelist.includes(fileExt.toLowerCase())) {
            this.dlListThumb.push({ type: "image", name: file });
            return file;
          } else {
            //File Icon is used for mapping to ANTD icons
            switch (fileExt.toLowerCase()) {
              case "txt":
                this.dlListThumb.push({
                  type: "others",
                  icon: "Text",
                  name: file,
                });
                break;
              case "pdf":
                this.dlListThumb.push({
                  type: "others",
                  icon: "Pdf",
                  name: file,
                });
                break;
              case "md":
                this.dlListThumb.push({
                  type: "others",
                  icon: "Markdown",
                  name: file,
                });
                break;
              case "xls":
              case "xlsx":
                this.dlListThumb.push({
                  type: "others",
                  icon: "Excel",
                  name: file,
                });
                break;
              case "doc":
              case "docx":
                this.dlListThumb.push({
                  type: "others",
                  icon: "Word",
                  name: file,
                });
                break;
              case "ppt":
              case "pptx":
                this.dlListThumb.push({
                  type: "others",
                  icon: "Ppt",
                  name: file,
                });
                break;
              default:
                this.dlListThumb.push({ type: "others", name: file });
            }
          }
        });

        //PART 2
        Promise.all(
          this.dlListThumb
            .filter(
              (file) =>
                //Check if file is an image
                file.type === "image"
            )
            .map((file, index) =>
              new Promise((resolve, reject) => {
                request(api.downloadFile, {
                  params: {
                    attachmentsId: attachId,
                    fileName: file.name,
                  },
                  responseType: "blob",
                })
                  .then((res) => {
                    //Add to data field
                    this.dlListThumb[index].data = res.data;
                    resolve();
                  })
                  .catch((err) => {
                    console.log("ERROR", err);
                    reject(err);
                  });
              }).catch(console.log)
            )
        )
          .then(() => {
            this.listLoading = false;
            resolveAll(this.dlListThumb);
          })
          .catch(console.log);
      });
    });
  }

  downloadFile(fileName) {
    this.loading = true;
    request(api.downloadFile, {
      params: {
        attachmentsId: this.attachId,
        fileName,
      },
      responseType: "blob",
    })
      .then((res) => {
        let fileName = res.headers["content-disposition"].split("filename=")[1];

        //PDF file, open instead of downloading
        if (fileName.split(".")[1] === "pdf") {
          window.open(
            URL.createObjectURL(
              new Blob([res.data], { type: "application/pdf" })
            )
          );
        }
        //All other files, just download
        else {
          this.download(res.data, fileName);
        }
      })
      .finally(() => (this.loading = false));
  }
}

export default new CommonStore();
