//import config from "config";
import user from "stores/user";
import timezone from "stores/timezone";
import customError from "components/customError";
import mapMsg from "utils/messageMapper";

const axios = require("axios");

const request = (api, data) => {
  const headers = {
    "Content-Type": "application/json",
    "auth-token": user.authToken,
    "login-admId": user.adminID,
    "audit-kw": "-",
    timeZone: timezone.tzFormat,
    ...api.headers,
    ...data.headers,
  };

  //Remove 'auth-token' and 'login-admId' if calling login API as they do not have the relevant headers
  if (api.url === "/login/login-1" || api.url === "/login/login-2") {
    delete headers["auth-token"];
    delete headers["login-admId"];
  }
  if (api.url === "/login/login-1") {
    delete headers["audit-kw"];
  }
  const timeout = 60000;
  //const baseURL = config.ENDPOINT;
  const baseURL = `${process.env.REACT_APP_API_HOST_NAME}${
    process.env.REACT_APP_API_PORT ? ":" + process.env.REACT_APP_API_PORT : ""
  }`;
  const instance = axios.create({
    baseURL,
    headers,
    timeout: data.timeout || api.timeout || timeout,
    //withCredentials: true,
  });

  //REQUEST Interceptors
  instance.interceptors.request.use(
    (req) => {
      //Set all values to null if UNDEFINED
      const defVal = null;
      if (req.data) {
        //Check if sending by a form data
        if (req.data instanceof FormData) {
          for (let pair of req.data.entries()) {
            pair[1] === "undefined" && req.data.set(pair[0], defVal);
          }
        }
        //otherwise, send by body
        else {
          req.data = Object.fromEntries(
            Object.entries(req.data).map((item) => [
              item[0],
              item[1] === undefined ? defVal : item[1],
            ])
          );
        }
      }
      return req;
    },
    (error) => {
      //Show Custom Error Message
      customError(error.toString());
      return Promise.reject(error);
    }
  );

  //RESPONSE Interceptors - ERROR Handling
  instance.interceptors.response.use(
    (res) => {
      //Check if there is a code, 0 is success for MM BO API, 1 is success for fileUpload, -1002 is for 文件格式不支持, -1 is for 请正确选择要上传的文件
      if (
        res.data.code &&
        res.data.code !== 0 &&
        res.data.code !== 1 &&
        res.data.code !== -1002 &&
        res.data.code !== -1 &&
        res.data.code !== 40002 &&
        res.data.code !== 90001 &&
        res.data.code !== 90002
      ) {
        //Show Custom Error Message
        !user.promptPwd && customError(mapMsg(res?.data));
      }

      switch (res.data.code) {
        //if message code is 200002, auth token not allowed, log user out
        case 200002:
          user.forceLogout = true;
          break;
        case 200107:
          customError("账号存在异常，无法登入");
          break;
        case 200208:
          customError("无法更新用户账号");
          break;
        case 200209:
          customError("无法获取用户主页");
          break;
        case 200210:
          customError("无法创建新用户");
          break;
        case 200601:
          customError("账号权限不足");
          break;
        case 200602:
          customError("用户角色已存在于系统");
          break;
        case 200603:
          customError("用户角色不存在于系统");
          break;
        case 200604:
          customError("无法创建新用户角色");
          break;
        case 200605:
          customError("无法替用户添加权限");
          break;
        case 200606:
          customError("无法删除用户角色");
          break;
        case 200607:
          customError("无法移除角色权限");
          break;
        case 200608:
          customError("无法移除角色权限");
          break;
        case 200609:
          customError("无法替用户添加用户角色");
          break;
        case 20047:
          customError("账号或密码错误");
          break;
        case 20049:
          customError("无法登入");
          break;
        case 40001:
          customError("该账户名已被注册");
          break;
        default:
      }

      return res;
    },
    (error) => {
      //Return custom msg for specific errors, convert to string in order to check for the specific error msg
      switch (error.toString()) {
        case "Error: Network Error":
          customError("网络错误");
          break;
        case "Error: Request failed with status code 500":
          customError("服务端 500 错误，请联系 BackOffice 系统支持人员");
          //customError("权限不足");
          break;
        default:
          //Show Custom Error Message
          customError(error.toString());
      }

      return Promise.reject(error);
    }
  );
  const url = data && data.id ? `${api.url}/${data.id}` : api.url;
  const params = data && data.params ? data.params : null;
  const form = data && data.form ? data.form : null;
  const formData = new FormData();
  switch (api.method) {
    case "get":
      return instance.get(url, { params, ...data });
    case "post":
      //Check if we have a form, if so, place it in the body
      if (form) {
        Object.keys(form).forEach((key) => {
          if (key === "attachments" && form[key].length > 0) {
            form[key].forEach((item) => formData.append(key, item));
          } else if (key === "attachments" && form[key].length <= 0) {
            formData.append(key, null);
          } else formData.append(key, form[key]);
        });
        return instance.post(url, formData, {
          //headers: api.headers
        });
      }
      //No Form data, Pass the params into the body instead
      else {
        return instance.post(url, params, {
          //headers: api.headers
        });
      }
    case "put":
      if (form) {
        Object.keys(form).forEach((key) => formData.append(key, form[key]));
        return instance.put(url, formData, {
          //headers: api.headers
        });
      }
      return instance.put(url, params, {
        //headers: api.headers
      });

    case "delete":
      if (form) {
        Object.keys(form).forEach((key) => formData.append(key, form[key]));
        return instance.delete(url, {
          data: formData,
        });
      }
      return instance.delete(url, {
        data: params,
      });
    default:
      return null;
  }
};
export default request;
