import { Tabs } from "antd";
import user from "stores/user";
import { observer } from "mobx-react-lite";
import { useHistory, useLocation } from "react-router";
import menuData from "config/hardcodedMenu";

const { TabPane } = Tabs;
const MenuTabs = () => {
  const history = useHistory();
  const location = useLocation();

  function changeTab(tab) {
    history.push(tab);
    //Using tab, we loop through the hardcoded menu to find the corresponding title
    /* menuData?.forEach((menu) => {
      menu?.children.forEach((subMenu) => {
        //If tab matches the url, then set title as selected key
        subMenu.url === tab && user.setSelectedKeys(subMenu.title);
      });
    }); */
    user.toggledTab = tab;

    //Check if the url is "-", and loop through those menu items containing children to set the correct highlighted tabs
    menuData?.forEach((menu) => {
      if(menu.url !== "-") {
        menu.url === tab && user.setSelectedKeys(menu.title);
      } else {
        menu?.children.forEach((subMenu) => {
          subMenu.url === tab && user.setSelectedKeys(subMenu.title);
        });
      }
    });

  }

  function removeTab(tab) {
    let index = user.menuTabs.findIndex((mTab) => mTab.key === tab);

    //Menu Tab exists and it is not the only tab
    if (index !== -1 && user.menuTabs.length > 1) {
      user.menuTabs.splice(index, 1); //Remove the tab
      //If the tab being closed is the current tab, Bring the user to the last tab
      if (tab === location.pathname) {
        let currPath = user.menuTabs[user.menuTabs.length - 1].key;

        //Match currPath with url and title from hardcoded menu to set the correct highlighted menu and tab
        menuData?.forEach((menu) => {
          if(menu.url !== "-") {
            menu.url === currPath && user.setSelectedKeys(menu.title);
            history.push(currPath);
          } else {
            menu?.children.forEach((subMenu) => {
              subMenu.url === currPath && user.setSelectedKeys(subMenu.title); 
            });
            history.push(currPath);
          }
        });
      }
    }
  }

  return (
    <Tabs
      onChange={changeTab}
      hideAdd
      type="editable-card"
      onEdit={removeTab}
      style={{ margin: '0px 10px' }}
      activeKey={location.pathname}
    >
      {user.menuTabs.map((menu) => (
        <TabPane
          tab={<span className={menu.alert && "tab-alert"}>{menu.name}</span>}
          key={menu.key}
        ></TabPane>
      ))}
    </Tabs>
  );
};

export default observer(MenuTabs);
