import { Alert, Layout as AntLayout, Card } from "antd";
import Header from "./header";
import Side from "./side";
import { observer } from "mobx-react-lite";
import { useHistory, useLocation } from "react-router";
import { useEffect, Suspense } from "react";
import MenuTabs from "./menuTabs";
//import menuData from "config/hardcodedMenu";
import Loader from "components/loader/loader";
import user from "stores/user";
import common from "stores/common";
//import i18n from "stores/i18n";
import home from "stores/orderMgm";
import Routes from "routes";

function Layout({ children, style }) {
  const history = useHistory();
  const location = useLocation();

  //Redirect user to login page (created for axios intercepter as history requires a functional component)
  useEffect(() => {
    if (user.forceLogout) {
      //clear interval
      clearInterval(common.refreshPg);

      //reset all after logout
      common.refreshPg = false;
      home.clearAllItems();

      history.push("/login");
      user.logout();
    }
  }, [user.forceLogout]);

  //Everytime user enters page, set selectedkey based on URL pathname
  useEffect(() => {
    //Add New Tab
    //Check if tabs already exists
    //Tab does not exist

    if (
      user.menuTabs.findIndex((mTab) => mTab.key === location.pathname) === -1
    ) {
      //Add to Tabs
      user.menuTabs.push({
        key: location.pathname,
        name: user.menuSettings.selectedKeys,
      });
    }
    //Tab already exists, just navigate to the tab
    else {
      history.push(location.pathname);
    }
  }, [history.location]);

  useEffect(() => {
    //Clear all error messages on page enter
    document
      .querySelectorAll(".ant-message-notice")
      .forEach((item) => item.remove());
    return () =>
      //Clear all error messages on page exit
      document
        .querySelectorAll(".ant-message-notice")
        .forEach((item) => item.remove());
  }, [history.location]);

  useEffect(() => {
    //Check if user has an adminID AND an auth token
    if (!user.adminID || !user.authToken) {
      history.push("/login");
      user.checkAuth = true;
    } else {
      user.checkAuth = true;
      //Check if user is logged in
      user
        .checkSession()
        .then((res) => {
          //User not logged in, redirect to login page
          if (!res) {
            history.push("/login");
          }
          //User logged in, redirect to home or other page
          //history.push("/login");
        })
        .catch((err) => {
          //Some Error, redirect to login page
          history.push("/login");
        })
        .finally(() => (user.checkAuth = true));
    }
  }, []);

  //Not yet authenticated, show loading screen
  if (user.checkAuth === false) {
    return <Loader />;
  }

  return (
    <AntLayout>
      <Side />
      <AntLayout>
        <AntLayout.Header>
          <Header />
        </AntLayout.Header>
        <AntLayout.Content>
          {common.showAlert && (
            <Alert
              message="Error"
              description={common.alertMsg}
              type="error"
              closable
              showIcon
            />
          )}
          <MenuTabs />
          <div
            className="container"
            style={{
              ...style,
              height: `calc(100vh - ${
                document.getElementsByClassName("container")[0]?.offsetTop
              }px)`,
              overflow: "auto",
              paddingBottom: 10,
            }}
          >
            <Card className="container-card" bordered={true}>
              <Suspense fallback={<Loader />}>
                <Routes />
              </Suspense>
            </Card>
          </div>
        </AntLayout.Content>
      </AntLayout>
    </AntLayout>
  );
}
export default observer(Layout);
