import { makeAutoObservable } from "mobx";
import { persistence, StorageAdapter } from "mobx-persist-store";
import { readStore, writeStore } from "stores/persist";
import request from "service/request";
import api from "service/api";

class TimeZoneStore {
  constructor() {
    makeAutoObservable(this);
  }

  loadingTz = false;
  //Timezone ID is only created as it is the only unique identifier for the "value" input
  //Data from backend as of 8th June 2021 has multiple duplicate timezones
  tzId = 0;
  timezone = "";
  tzList = [];

  get currency() {
    return this.tzList.find((tz) => tz.marketId === this.tzId)?.currencyCode;
  }

  get sym() {
    return this.tzList.find((tz) => tz.marketId === this.tzId)?.currencyCode;
  }

  get defaultDate() {
    let offset = new Date().getTimezoneOffset() / -60;
    return offset > 0 ? "+" + offset : offset.toString();
  }

  get tzFormat() {
    return `GMT${this.timezone}`;
  }

  callback = null;
  setCallback(funct) {
    this.callback = funct;
  }

  getTzList() {
    return new Promise((resolve, reject) => {
      /* this.loadingTz = true; */
      request(api.getTz, {})
        .then((res) => {
          if (res.data.data) {
            //Save the list of selectable timezones
            this.tzList = res.data.data.marketEntityList;

            //check if a timezone value exists, if it does not use the default value
            if (!window.localStorage.TimezoneStore) {
              //Save timezone ID
              this.tzId = parseInt(res.data.data.defaultId);

              //Save timezone
              this.timezone = res.data.data.defaultTimeZone;
            }
            resolve();
          }
        })
        .catch((err) => reject(err));
      /* .finally(() => (this.loadingTz = false)); */
    });
  }
}

export default persistence({
  name: "TimezoneStore",
  properties: ["tzId", "timezone", "tzList"],
  adapter: new StorageAdapter({ read: readStore, write: writeStore }),
  reactionOptions: { delay: 200 },
})(new TimeZoneStore());
