import i18n from "stores/i18n";
//This is for the parameterized messages from backend
export default function messageMapper(res) {
  //Check for message, if no message, use code to find mapped message in translation file
  let msg =
    res.message ||
    i18n.getText(`msg_code_${res.code}`) ||
    `${i18n.getText("errorCode")}: ${res.code}`;

  //loop through array and covert them with translation file
  res.msgParam?.forEach((param, index) => {
    let transParam = i18n.getText(`msg_code_${param}`) || param;

    //Replace values in Message with translated values from array
    msg = msg.replaceAll(`%${index}%`, transParam);
  });

  return msg;
}
