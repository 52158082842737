import {
  // Menu,
  Button,
  Dropdown,
  Space,
  Menu,
  Drawer,
  Skeleton,
  Descriptions,
  // Badge,
} from "antd";
import { useState } from "react";
import { observer } from "mobx-react-lite";
import { useHistory } from "react-router";
import Icon from "components/icon";
import ChangePassword from "./changePwd";
import i18n from "stores/i18n";
import user from "stores/user";
import common from "stores/common";
import home from "stores/orderMgm";
import header from "stores/layout/header";
import confirmDelete from "components/confirmDelete";
//import CustomSwitch from "components/ui/CustomSwitch";

function Header() {
  const history = useHistory();
  const [showPwdModal, setShowPwdModal] = useState(user.promptPwd);
  const [viewProfile, setViewProfile] = useState(false);
  // const changeLanguage = (e) => {
  //   i18n.changeLanguage(e.key);
  // };

  // const langMenu = (
  //   <Menu onClick={changeLanguage}>
  //     <Menu.Item key="en">English</Menu.Item>
  //     <Menu.Item key="ch">中文</Menu.Item>
  //   </Menu>
  // );

  const toggleProfileDropdown = (e) => {
    e.preventDefault();
  };

  const handleMenuClick = (e) => {
    switch (e.key) {
      case "1":
        setViewProfile(true);
        header.viewProfile();
        break;
      case "2":
        setShowPwdModal(true);
        break;
      case "3":
        logout();
        break;
      default:
        break;
    }
  };

  const menu = (
    <Menu
      onClick={handleMenuClick}
      items={[
        {
          key: "1",
          icon: <Icon type="UserOutlined" />,
          label: "Profile",
        },
        {
          key: "2",
          icon: (
            <Icon style={{ transform: "rotate( 45deg )" }} type="KeyOutlined" />
          ),

          label: "Change Password",
        },
        // {
        //   key: "3",
        //   icon: <Icon type="GoogleOutlined" />,
        //   label: "Bound Google Auth",
        // },
        {
          key: "3",
          icon: <Icon type="LogoutOutlined" />,
          label: i18n.getText("logout"),
        },
      ]}
    />
  );

  const BindToggle = ({ rowData }) => {
    const [loading, setLoading] = useState(false);
    return (
      <Button
        disabled={rowData.status === "D"}
        shape="circle"
        type="primary"
        icon={<Icon type="DisconnectOutlined" />}
        onClick={() => {
          setLoading(true);
          return new Promise((resolve, reject) => {
            confirmDelete(
              () => header.unbindGoogle(rowData),
              `Confirm unbind the google auth?`
            )
              .then((res) => {
                res === "onlyClose" ? reject() : resolve();
                setLoading(false);
              })
              .catch(() => reject());
          });
        }}
        loading={loading}
      />
    );
  };

  // function toggleGoogleLogin(checked, rowData) {
  //   userStore.oldRoles = rowData.role.map((role) => role.roleId);
  //   //Always reject as we want to handle the checked state from the mainRows data
  //   //instead of the result of the API as the data doesnt update immediately
  //   return new Promise((resolve, reject) => {
  //     userStore
  //       .editUser({
  //         ...rowData,
  //         googleLogin: checked ? "ON" : "OFF",
  //         roles: rowData.role.map((role) => role.id),
  //       })
  //       .finally(() => reject());
  //   });
  // }

  const logout = () => {
    //clear interval
    clearInterval(common.refreshPg);

    //reset all after logout
    common.refreshPg = false;
    home.clearAllItems();

    user.logout();
    history.push("/login");
  };

  const skeleton = () => {
    return (
      <Skeleton
        active
        loading={header.loadingProfile}
        paragraph={{ rows: 1 }}
        title={false}
      />
    );
  };

  /* useEffect(() => {
    const myInterval = 500000;

    //Interval to call the notification API
    header.getAllnotif();
    let allNotifInt = setInterval(() => {
      //API call to get any new message
      header.getAllnotif().then((newMsg) => {
        newMsg &&
          notification.open({
            key: newMsg?.msgId,
            message: `New Message from ${newMsg?.senderName}`,
            description: newMsg?.content,
            placement: "topRight",
            duration: 5,
            onClick: () => {
              //Close the Msg
              notification.close(newMsg?.msgId);

              //Bring user to the msg Listing page
              history.push("/msgCenter/msgListing");

              //Automatically click search button after a half second timeout to factor in page change
              setTimeout(() => {
                document.querySelector("#msgListBtn")?.click();
              }, 500);
            },
          });
      });
    }, myInterval);

    return () => {
      clearInterval(allNotifInt);
    };
  }, []); */

  // const MyBadge = ({ children, ...rest }) => {
  //   return (
  //     <Badge overflowCount={99} size="small" {...rest}>
  //       {children}
  //     </Badge>
  //   );
  // };

  // //Callback Error button
  // const [hasErr, setHasErr] = useState(false);
  // useEffect(() => {
  //   setHasErr(header.errCount > 0);
  // }, [header.errCount]);

  return (
    <>
      <div className="header">
        <div style={{ position: "relative", left: "-30px" }}>
          {user.menuSettings.collapsed ? (
            <span
              className="collapseButton"
              onClick={() => {
                user.setCollapsed(false);
              }}
            >
              <Icon type="MenuUnfoldOutlined" />
            </span>
          ) : (
            <span
              className="collapseButton"
              onClick={() => {
                user.setCollapsed(true);
              }}
            >
              <Icon type="MenuFoldOutlined" />
            </span>
          )}
        </div>
        <div>
          <Space wrap>
            <Dropdown overlay={menu} trigger="click">
              <Button type="primary" onClick={(e) => toggleProfileDropdown(e)} style={{minWidth: 120}}>
                <Space style={{ fontWeight: 600 }}>
                  {/* <Icon type="UserOutlined" /> */}
                  <p className="firstCaps">{user.username}</p>
                  <Icon type="DownOutlined" />
                </Space>
              </Button>
            </Dropdown>
            <Drawer
              title="User Profile"
              placement="right"
              onClose={() => setViewProfile(false)}
              open={viewProfile}
              width={350}
            >
              <Descriptions layout="vertical" column={1} bordered size="middle">
                <Descriptions.Item label="User Name">
                  {header.userData?.username}
                  {skeleton()}
                </Descriptions.Item>
                <Descriptions.Item label="Creation Date">
                  {header.userData?.creationDate}
                  {skeleton()}
                </Descriptions.Item>
                <Descriptions.Item label="Last Login">
                  {header.userData?.lastLogin}
                  {skeleton()}
                </Descriptions.Item>
                <Descriptions.Item label="Last Logout">
                  {header.userData?.lastLogout}
                  {skeleton()}
                </Descriptions.Item>
                <Descriptions.Item label="Status">
                  {skeleton()}
                  {header.userData?.status &&
                    (header.userData?.status === "Active" ? (
                      <>
                        <div className="status-active">&nbsp;</div>
                        <div className="status-text">
                          {header.userData?.status}
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="status-notactive">&nbsp;</div>
                        <div className="status-text">
                          {header.userData?.status}
                        </div>
                      </>
                    ))}
                </Descriptions.Item>
                <Descriptions.Item label="Bound Google Auth (Y/N)">
                  <Space>
                    {header.userData?.bindStatus}
                    {header.userData?.bindStatus === "Y" && (
                      <BindToggle rowData={header.userData} />
                    )}
                  </Space>
                  {skeleton()}
                </Descriptions.Item>
                {/* <Descriptions.Item label="Google Auth">
                  <CustomSwitch
                    //disabled={rowData.status === "D"}
                    funct={(checked) => toggleGoogleLogin(checked, header.userData)}
                    startChecked={header.userData?.googleAuth === "ON"}
                  />
                  {skeleton()}
                </Descriptions.Item> */}
                <Descriptions.Item label="Settings">
                  <Button
                    type="outlined"
                    style={{ width: "100%" }}
                    onClick={() => setShowPwdModal(true)}
                  >
                    <Icon
                      style={{ transform: "rotate( 45deg )" }}
                      type="KeyOutlined"
                    />
                    Change Password
                  </Button>
                </Descriptions.Item>
              </Descriptions>
            </Drawer>
          </Space>
        </div>
      </div>
      <ChangePassword
        showPwdModal={showPwdModal}
        setShowPwdModal={setShowPwdModal}
      />
    </>
  );
}

export default observer(Header);
