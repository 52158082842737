let chLabels = {
  //Main Menu Names
  mm_home: "主页",
  mm_userManagement: "用户管理",
  mm_audit: "审计",
  mm_memberManagement: "客户管理",
  mm_messageCenter: "Message Center",
  mm_promotionManagement: "Promo Mgm",
  mm_lotteryManagement: "Lott Mgm",
  mm_dashboard: "Dashboard",
  mm_riskManagement: "Risk Mgm",
  mm_reports: "Reports",
  mm_nonLotteryGame: "Non Lott Game",
  mm_miniGame: "Mini Game",
  mm_payment: "付款进行中",

  welcome: "欢饮",
};

export default chLabels;
