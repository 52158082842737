import { Form, Input, Button } from "antd";
import { useRef, useEffect } from "react";
import { useHistory } from "react-router";
import { observer } from "mobx-react";
// import menuData from "config/hardcodedMenu";
import user from "stores/user";
import common from "stores/common";
import GoogleOtp from "./GoogleOtp";
// import i18n from "stores/i18n";

function LoginPage() {
  const history = useHistory();
  const otpRef = useRef(null);

  useEffect(() => {
    if (otpRef.current) {
      otpRef.current.focus();
    }
  }, [otpRef.current]);

  const finalLogin = async () => {
    try {
      common.loading = true;
      await user.login();
      history.push("/orderMgm");
      //user.menuTabs = [{ key: "orderMgm", name: "Order Mgm" }];
      user.menuTabs = [user.orderKeyName];
      user.menuSettings.selectedKeys = [user.orderName];

      //bring user to last visited page, or else to homepage
      /*if (user.menuSettings.selectedKeys.length > 0) {
        let url = "";

        menuData.forEach((menu) =>
          menu.children.forEach(
            (subMenu) =>
              subMenu.title === user.menuSettings.selectedKeys[0] &&
              (url = subMenu.url)
          )
        );
        history.push(url);
      } else {
        history.push("/orderMgm");
      } */
    } catch (err) {
      console.log("login2 Failed", err);
    } finally {
      common.loading = false;
      user.showGoogleOtp = false;
    }
  };

  const login = async ({ username, password }) => {
    common.loading = true;
    user.username = username;
    user.password = password;
    try {
      //Gather all username related data
      await user.checkUserStatus(username);
      checkOtp();
     // user.showGoogleOtp = true;
    } catch (err) {
      //Show Custom Error Message
      console.log("Checking User failed.", err);
    }
    common.loading = false;
  };

    //Second API to be called
  //Check whether or not to show the Google OTP popup
  const checkOtp = async () => {
    if (
      user.userStatus.googleOTPMasterSwitch === "Y" &&
      user.userStatus.googleOTPSwitch === "ON"
    ) {
      user.showGoogleOtp = true;
    } else {
      user.showGoogleOtp = false;
      finalLogin();
    }
  };

  return (
    <div className="container">
      <div className="login">
        <Form name="basic" onFinish={login} layout="vertical">
          <Form.Item>
            <div className="title"><img src="./img/mm_logo.png" alt="logo" style={{width:150}} /></div>
          </Form.Item>
          <Form.Item
            label="Username"
            name="username"
            rules={[{ required: true }, { whitespace: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Password"
            name="password"
            rules={[
              { required: true, message: "Please input your password!" },
              { whitespace: true },
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item>
            <Button
              loading={common.loading}
              type="primary"
              htmlType="submit"
              block
            >
              Login
            </Button>
          </Form.Item>
        </Form>
      </div>

      <GoogleOtp finalLogin={finalLogin} otpRef={otpRef} />

      <style>{`
        html, body, #__next {
          height:100%
        }
        .title {
          font-size:18px;
          text-align:center;
        }
        .container {
          height: 100%;
          display:flex;
          justify-content:center;
          align-items: center;
        }
        .login {
          width:400px;
          height:260px;
        }
      `}</style>
    </div>
  );
}
export default observer(LoginPage);
