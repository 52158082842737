import { Input, Form, notification } from "antd";
import CustomFormModal from "components/ui/CustomFormModal";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useHistory } from "react-router";
import common from "stores/common";
import user from "stores/user";
import customError from "components/customError";
import errorMessage from "config/errorMsg";

export default observer(function ChangePassword({
  showPwdModal,
  setShowPwdModal
}) {
  const [form] = Form.useForm();
  const history = useHistory();
  const handleCancel = () => {
    setShowPwdModal(user.promptPwd);
  };

  useEffect(() => {
    form.resetFields();
  }, [showPwdModal]);

  function changePwd(formData) {
    common.updating = true;
    user
      .changePwd(formData)
      .then((res) => {
        if (res.data.data) {
          if (res.data.code === 0) {
            notification.open({
              message: "Password successfully changed",
              description: "Please relogin",
            });

            //if (user.promptPwd) {
              user.promptPwd = false;
              user.logout();
              history.push("/login");
           //}

            setShowPwdModal(false);
          } else if (res.data.code >= 200200 && res.data.code < 200207) {
            customError(errorMessage(res.data.code));
          }
        }
      })
      .finally(() => (common.updating = false));
  }

  return (
    <CustomFormModal
      title="Change Password"
      visible={showPwdModal}
      onCancel={handleCancel}
      closable={!user.promptPwd}
      maskStyle={user.promptPwd && { background: "white" }}
      onSubmit={changePwd}
    >
      <Form.Item
        name="oldPassword"
        label="Current Password"
        rules={[
          {
            required: true,
            message: "Please input your password!",
          },
        ]}
        hasFeedback
      >
        <Input.Password />
      </Form.Item>

      <Form.Item
        name="newPassword"
        label="New Password"
        rules={[
          {
            required: true,
            message: "Please input the password",
          },
          {
            message:
              "Must be at least 8 char and contains 1 number, 1 uppercase, and 1 symbol",
            validator: (_, value) => {
              if (
                /^(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^!&*+=]).*$/.test(
                  value
                )
              ) {
                return Promise.resolve();
              } else {
                return Promise.reject();
              }
            },
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (getFieldValue("oldPassword") !== value) {
                return Promise.resolve();
              }
              return Promise.reject(
                new Error("Password cannot be the same as the old password!")
              );
            },
          }),
        ]}
        hasFeedback
      >
        <Input.Password />
      </Form.Item>

      <Form.Item
        name="confirm"
        label="Confirm New Password"
        dependencies={["newPassword"]}
        hasFeedback
        rules={[
          {
            required: true,
            message: "Please confirm your password!",
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue("newPassword") === value) {
                return Promise.resolve();
              }
              return Promise.reject(
                new Error("The new passwords do not match!")
              );
            },
          }),
        ]}
      >
        <Input.Password />
      </Form.Item>
    </CustomFormModal>
  );
});
