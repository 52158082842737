/* eslint-disable import/no-anonymous-default-export */
import { makeAutoObservable } from "mobx";
import request from "service/request";
import api from "service/api";
import user from "../user";
import { updateSuccess } from "components/feedback";
import common from "stores/common";

export default new (class HeaderStore {
  myTaskCount = 0;
  infoTaskCount = 0;
  depTaskCount = 0;
  withdrawTaskCount = 0;
  errCount = 0;

  msgCount = 0;
  prevMsgCount;

  //Profile
  userData = null;
  loadingProfile = false;

  constructor() {
    makeAutoObservable(this);
  }

  async getAllnotif() {
    let res = await request(api.getAllNotif, {});
    if (res.data.data) {
      let resData = res.data.data;
      //Set Workflow task count
      this.myTaskCount =
        resData.countAssignedTask >= 0 ? resData.countAssignedTask : 0;
      this.infoTaskCount =
        resData.countInfoTask >= 0 ? resData.countInfoTask : 0;
      this.depTaskCount =
        resData.countUncollectDeposit >= 0 ? resData.countUncollectDeposit : 0;
      this.withdrawTaskCount =
        resData.countWithdrawalTask >= 0 ? resData.countWithdrawalTask : 0;

      //Errors
      this.errCount =
        resData.countFailedCbTask >= 0 ? resData.countFailedCbTask : 0;

      //Set Unread messages count
      this.msgCount = resData?.countUnreadMsg;

      //New Message
      let newMsg = resData.unreadMsgDto && resData.unreadMsgDto[0];
      console.log(
        "Prev count is:",
        this.prevMsgCount,
        "Current count is:",
        this.msgCount
      );

      //Store the old Message count for next cycle
      this.prevMsgCount = this.msgCount;

      if (newMsg && this.prevMsgCount === 0 && this.msgCount !== 0) {
        console.log("NEW MSG", newMsg);
        return new Promise((resolve) => {
          resolve(newMsg);
        });
      }
    }
  }

  viewProfile() {
    this.userData = null;
    this.loadingProfile = true;
    request(api.viewProfile, {
      params: {
        userId: user.adminID,
      },
    })
      .then((res) => {
        if (res.data.data) {
          if (res.data.code === 0) {
            this.userData = res.data.data;
          }
        }
      })
      .finally(() => (this.loadingProfile = false));
  }

  unbindGoogle(data) {
    return new Promise((resolve, reject) => {
      request(api.unbindOTP, {
        form: {
          id: data.userId,
        },
      })
        .then((res) => {
          if (res.data.data) {
            updateSuccess();
            this.viewProfile();
            resolve();
          }
          reject();
        })
        .catch(() => reject());
    });
  }

  resetPassword(formData) {
    common.updating = true;
    //Find admin's username with id
    //let getAdminUsername = this.mainRows.find((adm) => adm.id === formData.id);

    request(api.resetUserPass, {
      params: {
        userToResetId: user.adminID,
        oldPassword: formData.oldPassword,
        password: formData.password,
        confirmPassword: formData.confirm,
        userId: user.adminID,
      },
      //Get Headers
      headers: {
        "audit-kw": formData.username,
      },
    })
      .then((res) => {
        if (res.data.data) {
          if (res.data.code !== 40000) {
            updateSuccess("resetPwSuccess");
          }
          this.showResetPwModal = false;
        }
      })
      .finally(() => (common.updating = false));
  }
})();
