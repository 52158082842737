import { Modal } from "antd";
import { observer } from "mobx-react-lite";

// This is simply a wrapper component for the ant modal component
// Current props:
// maskClosable - prevent the user from closing the modal by clicking the mask (shaded outside area)
// keyboard - prevent the user from closing the modal by
export default observer(function CustomModal({ children, ...props }) {
  return (
    <Modal maskClosable={false} keyboard={false} {...props}>
      {children}
    </Modal>
  );
});
