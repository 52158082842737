import { Menu, Layout } from "antd";
import Icon from "components/icon";
import { observer } from "mobx-react-lite";
import { useHistory } from "react-router";
// import { camelToTitle } from "utils";
import user from "stores/user";
//import i18n from "stores/i18n";
import hardcodedMenu from "config/hardcodedMenu";

function Side() {
  const history = useHistory();
  //const location = useLocation();
  const SubMenu = Menu.SubMenu;
  const menuCode = localStorage.getItem("menucode") ? localStorage.getItem("menucode") : user.menuCode;

  return (
    <Layout.Sider
      width="250"
      trigger={null}
      collapsible
      collapsed={user.menuSettings.collapsed}
      collapsedWidth={0}
    >
      <div className="logo">
        {/* {i18n.getText("welcome")}{" "} */}
        {/* {user.username} */}
        <h1 style={{ color: "black", marginBottom: 0 }}>
          <img
            src="./img/mm_logo.png"
            alt="logo"
            style={{ width: 80, height: "auto" }}
          />
        </h1>
      </div>
      <Menu
        mode="inline"
        theme="dark"
        selectedKeys={user.menuSettings.selectedKeys}
        //Workaround for bug in antd version 4.16, which shows the opened menus when sidebar is hidden
        //Can be removed once fixed
        openKeys={user.menuSettings.collapsed ? [] : user.menuSettings.openKeys}
        onOpenChange={(openKeys) => {
          //When menu collapses, this will also be called, which we do not want, thus check if it is not collapsing
          if (!user.menuSettings.collapsed) {
            user.setOpenKeys(openKeys);
          }
        }}
        onDeselect={({ selectedKeys }) => user.setSelectedKeys(selectedKeys)}
        onSelect={({ selectedKeys }) => user.setSelectedKeys(selectedKeys)}
      >
        {/* {menus} */}
        {hardcodedMenu
          .filter((menu) => menuCode?.includes(menu.menuCode) && menu.url)
          .map((menu) =>
            menu.children ? (
              <SubMenu
                key={menu.title}
                {...(menu.icon && { icon: <Icon type={menu.icon} /> })}
                title={menu.title}
              >
                {menu.children.map((sm) => (
                  <Menu.Item
                    onClick={() => {
                      sm.url && history.push(sm.url);
                    }}
                    key={sm.title}
                  >
                    {sm.title}
                  </Menu.Item>
                ))}
              </SubMenu>
            ) : (
              <Menu.Item
                onClick={() => {
                  menu.url && history.push(menu.url);
                }}
                {...(menu.icon && { icon: <Icon type={menu.icon} /> })}
                key={menu.title}
                style={{ paddingLeft: 12 }}
              >
                {menu.title}
              </Menu.Item>
            )
          )}
      </Menu>
    </Layout.Sider>
  );
}
export default observer(Side);
