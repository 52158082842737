function errorMessage(code) {
  switch (code) {
    case 200200:
      return "密码更换失败";
    case 200201:
      return "您没有足够权限更改其他用户的密码";
    case 200202:
      return "密码不匹配";
    case 200203:
      return "无法获取原密码";
    case 200204:
      return "无法获取用户ID";
    case 200205:
      return "原密码错误";
    case 200206:
      return "原密码与新密码相同";
    default:
      break;
  }
}

export default errorMessage;
