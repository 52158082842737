const menu = [
  {
    title: "Order Management",
    icon: "HomeOutlined",
    url: "/orderMgm",
    menuCode: "OM",
  },
  {
    title: "User Management",
    i18n: "mm_userManagement",
    icon: "UserOutlined",
    url: "-",
    menuCode: "UM",
    new: true,
    children: [
      {
        title: "User Listing",
        url: "/userMgm/userListing",
        i18n: "sm_userListing",
      },
      {
        title: "Role Listing",
        url: "/userMgm/roleListing",
        i18n: "sm_roleListing",
      },
    ],
  },
  {
    title: "Black List",
    i18n: "mm_blackListing",
    icon: "SolutionOutlined",
    url: "-",
    menuCode: "BLACKLIST",
    new: true,
    children: [
      {
        title: "Black Listing",
        url: "/blackList/blackListListing",
        i18n: "sm_blackListing",
      },
      {
        title: "Black Listing History",
        url: "/blackList/blackListHistory",
        i18n: "sm_blackListHistory",
      },
    ],
  },
  {
    title: "Fraud List",
    i18n: "mm_fraudListing",
    icon: "SolutionOutlined",
    url: "-",
    menuCode: "FRAUD",
    new: true,
    children: [
      {
        title: "Fraud Listing",
        url: "/fraudList/fraudListing",
        i18n: "sm_fraudListing",
      },
    ],
  },
  {
    title: "Batch Job",
    i18n: "mm_BatchJob",
    icon: "CodeOutlined",
    url: "-",
    menuCode: "BAT",
    children: [
      {
        title: "Batch Job Listing",
        url: "/batchJob/batchJobListing",
        i18n: "sm_batchJobListing",
      },
    ],
  },
  {
    title: "Merchant Management",
    i18n: "mm_merchantManagement",
    icon: "TeamOutlined",
    url: "-",
    menuCode: "MER",
    children: [
      {
        title: "Merchant Listing",
        url: "/merchantMgm/merchantListing",
        i18n: "sm_merchantListing",
      },
    ],
  },
  // {
  //   title: "User Management",
  //   icon: "TeamOutlined",
  //   url: "/userMgm",
  // },
  // {
  //   title: "Merchant Management",
  //   icon: "TeamOutlined",
  //   url: "/merchantMgm",
  // },
  {
    title: "Deposit Management",
    i18n: "mm_depositManagement",
    icon: "PayCircleOutlined",
    url: "-",
    menuCode: "DEP",
    children: [
      {
        title: "Deposit Listing",
        url: "/depositMgm/depositListing",
        i18n: "sm_depositListing",
      },
    ],
  },
  {
    title: "Credit Rating",
    icon: "StarOutlined",
    url: "-",
    menuCode: "CRT",
    children: [
      {
        title: "Credit Score",
        url: "/creditRating/creditScore",
      },
      // {
      //   title: "Ignore Credit Users",
      //   url: "/ignoreCreditUsers",
      // },
      {
        title: "Credit Config",
        url: "/creditRating/creditConfig",
      },
      {
        title: "Credit Level",
        url: "/creditRating/creditLevel",
      },
    ],
  },
  {
    title: "System Configuration",
    icon: "ToolOutlined",
    url: "-",
    menuCode: "SC",
    children: [
      {
        title: "Matching Configuration",
        url: "/systemConfiguration/matchingConfiguration",
      },
    ],
  },
  {
    title: "Reports",
    icon: "DiffOutlined",
    url: "-",
    menuCode: "RPT",
    children: [
      {
        title: "Withdrawal KPI",
        url: "/reports/withdrawalKpi",
      },
      {
        title: "Deposit KPI",
        url: "/reports/depositKpi",
      },
    ],
  },
  {
    title: "User Profile",
    url: null,
    menuCode: "PF",
  },
];
export default menu;
